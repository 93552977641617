import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../store/appContext";
import { Card, CardBody, Col, Row } from "reactstrap";
import Knob from "../AllCharts/knob/knob";

const BalanzeSB = () => {
    const { store } = useContext(Context);
    const [steps, setsteps] = useState();
    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth() + 1;

    const formatearMes = (mes) => {
        return mes < 10 ? `0${mes}` : mes;
    };

    const depo = [];
    store.depositos.forEach((i) => {
        const mesFecha = i.fecha.slice(5, 7); // Extraer el mes de la fecha

        // Verificar si el mes en la fecha coincide con el mes actual formateado
        if (formatearMes(mesActual) === mesFecha) {
            depo.push(i.cantidad); // Almacena el estado si cumple con la condición
        }
    });
    const sumaDepo = depo.reduce((total, numero) => total + numero, 0);

    const Retir = [];
    store.retiros.forEach((i) => {
        if (i.fecha.slice(5, 7) == mesActual) {
            Retir.push(i.cantidad); // Almacena el estado si cumple con la condición
        }
    });
    const sumaRetir = Retir.reduce((total, numero) => total + numero, 0);

    const balance = sumaDepo - sumaRetir;

    const [color, setColor] = useState();

    useEffect(() => {
        if (balance > 0) {
            setColor("#0ab39c")
        }
        if (balance < 0) {
            setColor("#f54021")
        }

    })


    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    <h2>Balance SB of the Month</h2>
                </Col>
                <Col xl={3} sm={6}>
                    <Card>
                        <CardBody>
                            <div className="col-12 text-center">
                                <div className="">
                                    <div id="radialchart-1" className="apex-charts" dir="ltr">
                                        <h2>Deposits</h2>
                                        <Knob
                                            value={sumaDepo}
                                            fgColor="#564ab1"
                                            step={180000}
                                            height={180}
                                            width={180}
                                            min={-15000}
                                            max={sumaDepo + 1000}
                                            onChange={e => {
                                                setsteps(e)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col xl={3} sm={6}>
                    <Card>
                        <CardBody>
                            <div className="col-12 text-center">
                                <div className="">
                                    <div id="radialchart-2" className="apex-charts" dir="ltr">
                                        <h2>Withdrawals</h2>
                                        <Knob
                                            value={sumaRetir}
                                            fgColor="#ff3d60"
                                            step={10}
                                            height={180}
                                            width={180}
                                            min={-15000}
                                            max={sumaRetir + 1300}
                                            onChange={e => {
                                                setsteps(e)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col xl={3} sm={6}>
                    <Card>
                        <CardBody>
                            <div className="col-12 text-center">
                                <div className="">
                                    <div id="radialchart-3" className="apex-charts" dir="ltr">
                                        <h2>Balance</h2>
                                        <Knob
                                            value={balance}
                                            fgColor={color}
                                            step={10}
                                            height={180}
                                            width={180}
                                            min={-15000}
                                            max={balance + 500}
                                            onChange={e => {
                                                setsteps(e)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

            </Row>
        </React.Fragment>

    )
}
export default BalanzeSB;