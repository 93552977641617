import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListAcitivo = props => {

    // let roy = window.localStorage.getItem("my_token", JSON.stringify());
    // if (!roy) {
    //     window.location.href = '/';
    // } else {

    // }

    return (
        <div className="row g-0 text-center bg-white bb py-3 min1500">
            <div className="col-3 lines">{props.item}</div>
            <div className="col-3 lines">{props.modelo}</div>
            <div className="col-5 lines">{props.comentario}</div>
            <div className="col-1 lines"><Link to={`/edit-asset/${props.id}`}>Edit</Link></div>
        </div>
    );
};
ListAcitivo.propTypes = {
    activoID: PropTypes.number,
    modelo: PropTypes.string,
    item: PropTypes.string,
    comentario: PropTypes.string,
};
export default ListAcitivo;
