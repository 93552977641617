import React, { useContext, useState } from "react";
import { Context } from "../../store/appContext";
import { Row } from "reactstrap";
import ListMarketingReporte from "../MarketingReport/listMarketingReporte";
export default function MarketingReports() {
    const { store } = useContext(Context);
    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth() + 1;
    const anoActual = fechaActual.getFullYear();

    const formatearMes = (mes) => {
        return mes < 10 ? `0${mes}` : mes;
    };

    const [mesReportCs, setMesReportCs] = useState(formatearMes(mesActual));
    const [anoReportCs, setAnoReportCs] = useState(anoActual);
    return (
        <div className="py-1">
            <h2>Marketing Reports</h2>
            <Row className="py-4">
                <div className="col-lg-2">
                    <p>Year</p>
                    <input min={2023} max={2050} className="form-control selectInner shadow" type="number" placeholder={anoReportCs} onChange={e => setAnoReportCs(e.target.value)}></input>
                </div>
                <div className="col-lg-2">
                    <p>Month</p>
                    <input min={1} max={12} className="form-control selectInner shadow" type="text" placeholder={mesReportCs} onChange={e => setMesReportCs(e.target.value)}></input>
                </div>
            </Row>
            <Row className="g-0 overflow-scroll">
                <Row className="row g-0 text-center bg-base text-white py-3 min1500">
                    <div className="col-3 lines">Name</div>
                    <div className="col-1 lines">Date</div>
                    <div className="col-2 lines">Brand</div>
                    <div className="col-5 lines">Report</div>
                    <div className="col-1 lines">Edit</div>
                </Row>
                {
                    store.marketingReportes
                        .filter(item => {
                            const filterCondition = item.fecha.slice(0, 4) == anoReportCs && item.fecha.slice(5, 7) == mesReportCs;
                            return filterCondition;
                        })
                        .sort((a, b) => new Date(b.fecha) - new Date(a.fecha))
                        .map((item, index) => {
                            return (
                                <div key={index} className="col-12">
                                    <ListMarketingReporte
                                        id={index}
                                        reporteID={item.reporteID}
                                        fecha={item.fecha}
                                        usuario={item.usuario}
                                        marca={item.marca}
                                        reporte={item.reporte}
                                    />
                                </div>
                            );
                        })}
            </Row>
        </div>
    )
}
