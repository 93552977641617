import React, { useState, useContext } from "react";
import { useParams } from 'react-router-dom';
import { Context } from "../../store/appContext";
import { useNavigate } from 'react-router-dom';
import { Row } from "reactstrap";

export default function EditBalanceBuckeye() {
    document.title = "Company | Edit Collection Info";
    const navigate = useNavigate();
    const params = useParams();
    const { store } = useContext(Context);

    let actualizar = () => {
        window.location.reload(true);
    }

    const marcasPPH = []
    store.marcas.forEach((i) => {
        if (i.comentario === "PPH") {
            marcasPPH.push(i.marca); // Almacena la marca si cumple con la condición
        }
    });

    const agentesPPH = [];
    store.agentes.forEach((i) => {
        agentesPPH.push(i.agencia); // Almacena el nombre si cumple con la condición
    });

    let userLog = localStorage.getItem('Name');

    const [fecha, setFecha] = useState(store.balanceBuckeye[params.theid] && store.balanceBuckeye[params.theid].fecha);
    const [agente, setAgente] = useState(store.balanceBuckeye[params.theid] && store.balanceBuckeye[params.theid].agente);
    const [pphPlayers, setPphPlayers] = useState(store.balanceBuckeye[params.theid] && store.balanceBuckeye[params.theid].pphPlayers);
    const [lbPlayers, setLbPlayers] = useState(store.balanceBuckeye[params.theid] && store.balanceBuckeye[params.theid].lbPlayers);
    const [pbPlayers, setPbPlayers] = useState(store.balanceBuckeye[params.theid] && store.balanceBuckeye[params.theid].pbPlayers);
    const [liveDealerLoss, setLiveDealerLoss] = useState(store.balanceBuckeye[params.theid] && store.balanceBuckeye[params.theid].liveDealerLoss);
    const [proCasinoLoss, setProCasinoLoss] = useState(store.balanceBuckeye[params.theid] && store.balanceBuckeye[params.theid].proCasinoLoss);

    //filtra los agentes por la seleccion del usuario y almacena los datos
    let precioAgentePPH = [];
    let porcentageliveDealer = [];
    let precioLB = [];
    let proBulderPrice = [];
    store.agentes.forEach((i) => {
        if (agente == i.agencia) {
            precioAgentePPH.push(i.precioPPH); // Almacena el dato si cumple con la condición
            porcentageliveDealer.push(i.porcentajeLiveDealer); // Almacena el dato si cumple con la condición
            precioLB.push(i.precioLiveBetting); // Almacena el dato si cumple con la condición
            proBulderPrice.push(i.precioLivePropBuilder); // Almacena el dato si cumple con la condición
        }
    });
    //logica de sumatoria de datos 
    let pphTotalSum = pphPlayers * precioAgentePPH;
    let lbTotalSum = lbPlayers * precioLB;
    let pbTotalSum = pbPlayers * proBulderPrice;
    let servicesTotalSum = pphTotalSum + lbTotalSum + pbTotalSum;
    let ldTotalSum = (liveDealerLoss * porcentageliveDealer) / 100;
    let proCasinoLossNum = parseFloat(proCasinoLoss);
    let grandTotal = servicesTotalSum + ldTotalSum + proCasinoLossNum;

    const crear = e => {
        e.preventDefault();
        const body = {
            agente: agente,
            fecha: fecha,
            pphPrice: precioAgentePPH,
            pphPlayers: pphPlayers,
            pphTotal: pphTotalSum,
            lbPlayers: lbPlayers,
            lbPrice: precioLB,
            lbTotal: lbTotalSum,
            pbPlayers: pbPlayers,
            pbPrice: proBulderPrice,
            pbTotal: pbTotalSum,
            totalSpLbPb: servicesTotalSum,
            liveDealerLoss: liveDealerLoss,
            liveDealerPorcentaje: porcentageliveDealer,
            liveDealerTotal: ldTotalSum,
            proCasinoLoss: proCasinoLoss,
            usuario: userLog,
        };
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/cobrosPPHBuckeye/" + store.balanceBuckeye[params.theid].cobroID, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                
                alert("Balance was updated");
                navigate('/balance-buckeye');
                actualizar();
            })
            .catch(err => (console.log(body) ));
    };

    return (
        <React.Fragment>
            <div className="page-content">
            </div>
            <form onSubmit={crear} id="balance">
                <div className="card p-1 text-center">
                    <Row className="g-0">
                        <div className="col">
                            <div className="col-12 fw-bold">Date</div>
                            <input className="form-control selectInner shadow" type="text" placeholder="Date" aria-label="Date" disabled defaultValue={store.balanceBuckeye[params.theid] && store.balanceBuckeye[params.theid].fecha} onChange={e => setFecha(e.target.value)} />
                        </div>
                        <div className="col">
                            <div className="col-12 fw-bold">Agency</div>
                            <select className="form-select" name="Agent" aria-label="Agent" value={agente} >
                                {
                                    agentesPPH.map((index) => {
                                        return (
                                            <option key={index} name="ano" value={index} >{index}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col">
                            <div className="col-12 fw-bold">PPH Players</div>
                            <input className="form-control selectInner shadow" type="number" placeholder="PPH Players" aria-label="PPH Players" defaultValue={store.balanceBuckeye[params.theid] && store.balanceBuckeye[params.theid].pphPlayers} onChange={e => setPphPlayers(e.target.value)} />
                        </div>
                        <div className="col">
                            <div className="col-12 fw-bold">PPH Price</div>
                            <input className="form-control selectInner shadow" type="number" aria-label="PPH Price"
                                placeholder={precioAgentePPH} disabled />
                        </div>
                        <div className="col">
                            <div className="col-12 fw-bold text-danger">PPH Total</div>
                            <input className="form-control selectInner shadow" disabled type="number" placeholder={pphTotalSum} aria-label="PPH Total" />
                        </div>
                        <div className="col">
                            <div className="col-12 fw-bold">LB Players</div>
                            <input className="form-control selectInner shadow" type="number" placeholder="LB Players" aria-label="LB Players" defaultValue={store.balanceBuckeye[params.theid] && store.balanceBuckeye[params.theid].lbPlayers} onChange={e => setLbPlayers(e.target.value)} />
                        </div>
                        <div className="col">
                            <div className="col-12 fw-bold">LB Price</div>
                            <input className="form-control selectInner shadow" disabled type="number" placeholder={precioLB} aria-label="LB Price" />
                        </div>
                        <div className="col">
                            <div className="col-12 fw-bold text-danger">LB Total</div>
                            <input className="form-control selectInner shadow" disabled type="number" placeholder={lbTotalSum} aria-label="LB Total" />
                        </div>
                        <div className="col">
                            <div className="col-12 fw-bold">PB Players</div>
                            <input className="form-control selectInner shadow" type="number" placeholder="PB Players" aria-label="PB Players" defaultValue={store.balanceBuckeye[params.theid] && store.balanceBuckeye[params.theid].pbPlayers} onChange={e => setPbPlayers(e.target.value)} />
                        </div>
                        <div className="col">
                            <div className="col-12 fw-bold">PB Price</div>
                            <input className="form-control selectInner shadow" disabled type="number" placeholder={proBulderPrice} aria-label="PB Price" />
                        </div>
                        <div className="col">
                            <div className="col-12 fw-bold text-danger">PB Total</div>
                            <input className="form-control selectInner shadow" disabled type="number" placeholder={pbTotalSum} aria-label="PB Total" />
                        </div>
                        <div className="col">
                            <div className="col-12 fw-bold text-danger">Services Total</div>
                            <input className="form-control selectInner shadow" disabled type="number" placeholder={servicesTotalSum} aria-label="Services Total" />
                        </div>
                        <div className="col">
                            <div className="col-12 fw-bold">LD Lost</div>
                            <input className="form-control selectInner shadow" type="number" placeholder="LD Lost" aria-label="LD Lost" defaultValue={store.balanceBuckeye[params.theid] && store.balanceBuckeye[params.theid].liveDealerLoss} onChange={e => setLiveDealerLoss(e.target.value)} />
                        </div>
                        <div className="col">
                            <div className="col-12 fw-bold">LD %</div>
                            <input className="form-control selectInner shadow" disabled type="number" placeholder={porcentageliveDealer} aria-label="LD %" />
                        </div>
                        <div className="col">
                            <div className="col-12 fw-bold text-danger">LD Total</div>
                            <input className="form-control selectInner shadow" disabled type="number" placeholder={ldTotalSum} aria-label="LD Total" />
                        </div>
                        <div className="col">
                            <div className="col-12 fw-bold">Pro Casino</div>
                            <input className="form-control selectInner shadow" type="number" placeholder="Pro Casino" aria-label="Pro Casino" defaultValue={store.balanceBuckeye[params.theid] && store.balanceBuckeye[params.theid].proCasinoLoss} onChange={e => setProCasinoLoss(e.target.value)} />
                        </div>
                    </Row>
                    <Row>
                        <div className="col-2 p-3">
                            <div className="card shadow bg-base text-white">
                                <div className="card-body fs-4">
                                    <div className="col-12">
                                        Grand Total: {grandTotal}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 py-3">
                            <button type="submit" className="btn btn-danger">Save</button>
                        </div>
                    </Row>
                </div>
            </form>
        </React.Fragment>
    );
};