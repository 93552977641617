import React, { useState, useContext } from "react";
import { Context } from "../../store/appContext";
import { Card, CardBody, Col, Row } from "reactstrap";
import Knob from "../AllCharts/knob/knob";

const CollectionsAffiliate = () => {
  const { store } = useContext(Context);

  const fechaActual = new Date();
  const mesActual = fechaActual.getMonth() + 1;

  const pend = [];
  const confirmed = [];
  const montoConfirmed = [];
  const montoConfirmedDolar = [];


  store.cobroAffiliate.forEach((i) => {
    if (i.estado === "Confirmed" && i.fechaCorte.slice(5, 7) == mesActual) {
      confirmed.push(i.estado); // Almacena el estado si cumple con la condición
      const montoBTC = parseFloat(i.montoBTC); // Convertir la cadena montoBTC a número flotante
      if (!isNaN(montoBTC)) { // Verificar si el resultado de parseFloat es un número válido
        montoConfirmed.push(montoBTC); // Almacena el monto en montoConfirmed
      }
      const montoDolar = parseFloat(i.monto_dolar); // Convertir la cadena montoBTC a número flotante
      if (!isNaN(montoDolar)) { // Verificar si el resultado de parseFloat es un número válido
        montoConfirmedDolar.push(montoDolar); // Almacena el monto en montoConfirmed
      }
    }
    if (i.estado === "Pending" && i.fechaCorte.slice(5, 7) == mesActual) {
      pend.push(i.estado); // Almacena el estado si cumple con la condición
      const montoBTC = parseFloat(i.montoBTC); // Convertir la cadena montoBTC a número flotante
      if (!isNaN(montoBTC)) { // Verificar si el resultado de parseFloat es un número válido
        montoConfirmed.push(montoBTC); // Almacena el monto en montoConfirmed
      }
      const montoDolar = parseFloat(i.monto_dolar); // Convertir la cadena montoBTC a número flotante
      if (!isNaN(montoDolar)) { // Verificar si el resultado de parseFloat es un número válido
        montoConfirmedDolar.push(montoDolar); // Almacena el monto en montoConfirmed
      }
    }
  });
  const pendTotal = pend.length; // Obtener la longitud del array 'pend';

  const confirmedTotal = confirmed.length; // Obtener la longitud del array 'pend';

  const sumaMontoBTC = montoConfirmed.reduce((acumulador, numero) => {
    return acumulador + numero;
  }, 0); // sumatoria de monto nen BTC

  const sumaMontoDolar = montoConfirmedDolar.reduce((acumulador, numero) => {
    return acumulador + numero;
  }, 0); // sumatoria de monto nen BTC

  const [steps, setsteps] = useState();
  return (
    <React.Fragment>
      <h2>PPH Collections this month</h2>
      <Row>
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="col-12 text-center">
                <div className="">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    <h2>Confirmed</h2>
                    <Knob
                      value={confirmedTotal}
                      fgColor="#0ab39c"
                      step={10}
                      height={100}
                      width={100}
                      min={-15000}
                      max={1500}
                      onChange={e => {
                        setsteps(e)
                      }}
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="col-12 text-center">
                <div className="">
                  <div id="radialchart-2" className="apex-charts" dir="ltr">
                    <h2>Pending</h2>
                    <Knob
                      value={pendTotal}
                      fgColor="#ff3d60"
                      step={10}
                      height={100}
                      width={100}
                      min={-15000}
                      max={1500}
                      onChange={e => {
                        setsteps(e)
                      }}
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="col-12 text-center">
                <div className="">
                  <div id="radialchart-3" className="apex-charts" dir="ltr">
                    <h2>Total BTC</h2>
                    <Knob
                      value={sumaMontoBTC}
                      fgColor="#564ab1"
                      step={10}
                      height={100}
                      width={100}
                      min={-15000}
                      max={15000}
                      onChange={e => {
                        setsteps(e)
                      }}
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="col-12 text-center">
                <div className="">
                  <div id="radialchart-3" className="apex-charts" dir="ltr">
                    <h2>Total Deposit</h2>
                    <Knob
                      value={sumaMontoDolar}
                      fgColor="#0bb197"
                      step={10}
                      height={100}
                      width={100}
                      min={-15000}
                      max={15000}
                      onChange={e => {
                        setsteps(e)
                      }}
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CollectionsAffiliate;
