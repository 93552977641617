import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListUsuarios = props => {

    // let roy = window.localStorage.getItem("my_token", JSON.stringify());
    // if (!roy) {
    //     window.location.href = '/';
    // } else {

    // }

    return (
        <div className="row g-0 text-center bg-white bb py-3 min1500">
            <div className="col-6 lines">{props.nombre}</div>
            <div className="col-5 lines">{props.puesto}</div>
            <div className="col-1 lines"><Link to={`/edit-user/${props.id}`}>Edit</Link></div>
        </div>
    );
};
ListUsuarios.propTypes = {
    usuarioID: PropTypes.number,
    nombre: PropTypes.string,
    puesto: PropTypes.string,
    password: PropTypes.string
};
export default ListUsuarios;
