import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListTiquete = props => {

    return (
        <div className="row g-0 text-center min1500" >
            <div className="col">
                <p>{props.sender}</p>
            </div>
            <div className="col">
                <p>{props.recipient}</p>
            </div>
            <div className="col">
                <p>{props.content}</p>
            </div>
            <div className="col">
                <p>{props.fecha}</p>
            </div>
            <div className="col">
                <p>{props.estado}</p>
            </div>
            <div className="col">
                <Link to={`/edit-ticket/${props.id}`}>Edit</Link>
            </div>
        </div>
    );
};
ListTiquete.propTypes = {
    tiqueteID: PropTypes.number,
    sender: PropTypes.string,
    recipient: PropTypes.string,
    content: PropTypes.string,
    fecha: PropTypes.string,
    estado: PropTypes.string,
};
export default ListTiquete;
