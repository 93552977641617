import React from "react";
import { Navigate } from "react-router-dom";

//Empresa
import Empresa from "../Pages/Empresa/RecordEmpresa.js";
import EditEmpresa from "../Pages/Empresa/editEmpresa.js";
//Activos
import EditActivo from "../Pages/Activos/editActivo.js";
import RecordActivo from "../Pages/Activos/recordActivo.js";
//Cobros
import EditCobros from "../Pages/Cobros/editCobros.js";
import RecordCobros from "../Pages/Cobros/recordCobros.js";
//Cobros WL
import EditCobrosWL from "../Pages/CobrosWL/editCobrosWL.js";
import RecordCobrosWL from "../Pages/CobrosWL/recordCobrosWL.js";
//cobros Percentage

import EditcobroPercentage from "../Pages/CobrosPercentage/editCobrosPercentage.js";
import RecordCobrosPercentage from "../Pages/CobrosPercentage/recordCobrosPercentage.js";
//Cobros Review

import EditCobroReview from "../Pages/CobrosReview/editCobrosReview.js";
import RecordCobrosReview from "../Pages/CobrosReview/recordCobrosReview.js";
//Cobros Affiliate

import EditCobroAffiliate from "../Pages/CobrosAffilate/editCobrosAffiliate.js";
import RecordCobrosAffiliate from "../Pages/CobrosAffilate/recordCobrosAffiliate.js";
// Depositos SB

import EditDeposito from "../Pages/DepositosJugadores/editDeposito.js";
import RecordDeposito from "../Pages/DepositosJugadores/recordDeposito.js";
//Depositos Casino

import EditDepositoCasino from "../Pages/DepositosJugadoresCasino/editDeposito.js";
import RecordDepositoCasino from "../Pages/DepositosJugadoresCasino/recordDeposito.js";
//Balance Jazz

import EditBalanceJazz from "../Pages/BalanceJazz/editBalanceJazz.js";
import RecordBalanceJazz from "../Pages/BalanceJazz/recordBalanceJazz.js";
//Balance Buckeye

import EditBalanceBuckeye from "../Pages/BalanceBuckeye/editBalanceBuckeye.js";
import RecordBalanceBuckeye from "../Pages/BalanceBuckeye/recordBalanceBuckeye.js";
//Balance Horizon

import EditBalanceHorizon from "../Pages/BalanceHorizon/editBalanceHorizon.js";
import RecordBalanceHorizon from "../Pages/BalanceHorizon/recordBalanceHorizon.js";
//Balance PPHCR

import EditBalancePPHCR from "../Pages/BalancePPCCR/editBalancePPHCR.js";
import RecordBalancePPHCR from "../Pages/BalancePPCCR/recordBalancePPHCR.js";
// Retiros SB

import EditRetiro from "../Pages/Retiros/editRetiro.js";
import RecordRetiro from "../Pages/Retiros/recordRetiro.js";
//Retiros Casino

import EditRetiroCasino from "../Pages/RetirosCasino/editRetiro.js";
import RecordRetiroCasino from "../Pages/RetirosCasino/recordRetiro.js";
//Agentes

import EditAgent from "../Pages/Agentes/editAgent.js";
import RecordAgents from "../Pages/Agentes/recordAgents.js";
//Clientes Percentage

import EditPercentageClient from "../Pages/clientesPercentage/editPercentageClient.js";
import RecordPercentageClient from "../Pages/clientesPercentage/recordPercentageClient.js";
//Customers follow up
import { CreateCustomer } from "../Pages/customerFollow/createCustomer.js";
import EditCustomers from "../Pages/customerFollow/editCustomer.js";
import RecordCustomers from "../Pages/customerFollow/recordCustomers.js";
//Afiliados

import EditAfiliado from "../Pages/Afiliados/editAfiliado.js";
import RecordAfiliado from "../Pages/Afiliados/recordAfiliado.js";
//WL Custumer

import EditWLCustomer from "../Pages/WLCustomer/editWLCustomer.js";
import RecordWLCustomer from "../Pages/WLCustomer/recordWLCustomer.js";
//Review Customer

import EditReviewCustomer from "../Pages/reviewCustomer/editReviewCustomer.js";
import RecordReviewCustomer from "../Pages/reviewCustomer/recordReviewCustomer.js";
//Jugadores

import EditJugador from "../Pages/jugadores/editJugador.js";
import RecordJugador from "../Pages/jugadores/recordJugador.js";
//Jugadores Casino

import EditJugadorCasino from "../Pages/JugadoresCasino/editJugador.js";
import RecordJugadorCasino from "../Pages/JugadoresCasino/recordJugador.js";
//Marcas
import EditMarca from "../Pages/Marcas/editMarca.js";
import RecordMarca from "../Pages/Marcas/recordMarca.js";
//Suministros

import EditSuministro from "../Pages/Suministros/editSuministro.js";
import RecordSuministro from "../Pages/Suministros/recordSuministro.js";
//Software

import EditSoftware from "../Pages/Software/editSoftware.js";
import RecordSoftware from "../Pages/Software/recordSoftware.js";
//Reportes PPH

import EditReportePPH from "../Pages/ReportesPPH/editReportePPH.js";
import RecordReportePPH from "../Pages/ReportesPPH/recordReportePPH.js";
//Reportes de Wl

import EditReporteWL from "../Pages/WL Reports/editReporteWL.js";
import RecordReporteWL from "../Pages/WL Reports/recordReporteWL.js";
//Reportes Percentage

import EditReportePercentage from "../Pages/Reports Percentage/editReportePercentage.js";
import RecordReportePercentage from "../Pages/Reports Percentage/recordReportePercentage.js";
//Reportes Review

import EditReporteReview from "../Pages/Reportes Review/editReporteReview.js";
import RecordReporteReview from "../Pages/Reportes Review/recordReporteReview.js";
//Reportes Affiliate

import EditReporteAffiliate from "../Pages/ReportAffiliate/editReporteAffiliate.js";
import RecordReporteAffiliate from "../Pages/ReportAffiliate/recordReporteAffiliate.js";
//Marketing Reportes

import EditMarketingReporte from "../Pages/MarketingReport/editMarketingReporte.js";
import RecordMarketingReporte from "../Pages/MarketingReport/recordMarketingReporte.js";
//Colaborador

import EditColaborador from "../Pages/Colaborador/editColaborador.js";
import RecordColaborador from "../Pages/Colaborador/recordColaborador.js";
//Usuarios

import EditUsuario from "../Pages/Usuarios/editUsusario.js";
import RecordUsuario from "../Pages/Usuarios/recordUsuario.js";
//Templates

import EditTemplate from "../Pages/Templates/editTemplate.js";
import RecordTemplate from "../Pages/Templates/recordTemplate.js";
//Tiquetes
import { CreateTiquete } from "../Pages/Tiquetes/createTiquete.js";
import EditTiquete from "../Pages/Tiquetes/editTiquete.js";
import RecordTiquete from "../Pages/Tiquetes/RecordTiquete.js";
//Freelance

import EditFreelance from "../Pages/Freelance/editFreelance.js";
import RecordFreelance from "../Pages/Freelance/recordFreelance.js";
//Dashboard
import Dashboard from "../Pages/Dashboard";



// Import E-mail
// import Inbox from "../Pages/E-mail/Inbox";
// import ReadEmail from "../Pages/E-mail/ReadEmail";
import EmailCompose from "../Pages/E-mail/EmailCompose";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";

// Import Authentication Inner Pages
import Login1 from "../Pages/AuthenticationPages/Login";
import Register1 from "../Pages/AuthenticationPages/Register";
import RecoverPassword from "../Pages/AuthenticationPages/RecoverPassword";
import LockScreen from "../Pages/AuthenticationPages/LockScreen";

// Import Utility Pages

import Maintenance from "../Pages/Utility/Maintenance-Page";
import ComingSoon from "../Pages/Utility/ComingSoon-Page";

import Error404 from "../Pages/Utility/Error404-Page";
import Error500 from "../Pages/Utility/Error500-Page";






const authProtectedRoutes = [
  //Empresa
  { path: "/edit-company/:theid", component: <EditEmpresa /> },
  { path: "/company", component: <Empresa /> },
  //Activo
  { path: "/edit-asset/:theid", component: <EditActivo /> },

  { path: "/assets", component: <RecordActivo /> },
  //Cobro
  { path: "/edit-collection/:theid", component: <EditCobros /> },

  { path: "/collections", component: <RecordCobros /> },
  //Cobro WL
  { path: "/edit-wl-collection/:theid", component: <EditCobrosWL /> },

  { path: "/wl-collections", component: <RecordCobrosWL /> },
  //Cobro Percentage
  { path: "/edit-percentage-collection/:theid", component: <EditcobroPercentage /> },

  { path: "/percentage-collections", component: <RecordCobrosPercentage /> },
  //Cobro Review
  { path: "/edit-review-collection/:theid", component: <EditCobroReview /> },

  { path: "/review-collections", component: <RecordCobrosReview /> },
  //Cobro Affiliate
  { path: "/edit-affiliate-collection/:theid", component: <EditCobroAffiliate /> },

  { path: "/affiliate-collections", component: <RecordCobrosAffiliate /> },
  //Balance Jazz
  { path: "/edit-balance-jazz/:theid", component: <EditBalanceJazz /> },

  { path: "/balance-jazz", component: <RecordBalanceJazz /> },
  //Balance Buckeye
  { path: "/edit-balance-buckeye/:theid", component: <EditBalanceBuckeye /> },
 
  { path: "/balance-buckeye", component: <RecordBalanceBuckeye /> },
  //Balance Horizon
  { path: "/edit-balance-horizon/:theid", component: <EditBalanceHorizon /> },

  { path: "/balance-horizon", component: <RecordBalanceHorizon /> },
  //Balance PPHCR
  { path: "/edit-balance-pphcr/:theid", component: <EditBalancePPHCR /> },

  { path: "/balance-pphcr", component: <RecordBalancePPHCR /> },
  //Depositos SB
  { path: "/edit-deposit/:theid", component: <EditDeposito /> },

  { path: "/deposits", component: <RecordDeposito /> },
  //Depositos Casino
  { path: "/edit-deposit-casino/:theid", component: <EditDepositoCasino /> },

  { path: "/deposits-casino", component: <RecordDepositoCasino /> },
  //Retiros SB
  { path: "/edit-withdrawal/:theid", component: <EditRetiro /> },

  { path: "/withdrawals", component: <RecordRetiro /> },
  //Retiros Casino
  { path: "/edit-casino-withdrawal/:theid", component: <EditRetiroCasino /> },

  { path: "/withdrawals-casino", component: <RecordRetiroCasino /> },
  //Agentes
  { path: "/edit-agent/:theid", component: <EditAgent /> },

  { path: "/agents", component: <RecordAgents /> },
  //Clientes Percentage
  { path: "/edit-customer-percentage/:theid", component: <EditPercentageClient /> },

  { path: "/customer-percentage", component: <RecordPercentageClient /> },
  //Afiliados
  { path: "/edit-affiliate/:theid", component: <EditAfiliado /> },

  { path: "/affiliates", component: <RecordAfiliado /> },
  //Wl Customer
  { path: "/edit-wl-custumer/:theid", component: <EditWLCustomer /> },

  { path: "/wl-custumers", component: <RecordWLCustomer /> },
  //Review Customer
  { path: "/edit-review-custumer/:theid", component: <EditReviewCustomer /> },

  { path: "/review-custumers", component: <RecordReviewCustomer /> },
  //Customer follow up
  { path: "/create-custumer", component: <CreateCustomer /> },
  { path: "/edit-customer/:theid", component: <EditCustomers /> },

  { path: "/customers", component: <RecordCustomers /> },
  //Jugadores
  { path: "/edit-player/:theid", component: <EditJugador /> },
  
  { path: "/players", component: <RecordJugador /> },
  //Jugadores Casino
  { path: "/edit-player-casino/:theid", component: <EditJugadorCasino /> },

  { path: "/players-casino", component: <RecordJugadorCasino /> },
  //Marcas
  { path: "/edit-brand/:theid", component: <EditMarca /> },

  { path: "/brands", component: <RecordMarca /> },
  //Suministros
  { path: "/edit-supplies/:theid", component: <EditSuministro /> },

  { path: "/supplies", component: <RecordSuministro /> },
  // Software
  { path: "/edit-software/:theid", component: <EditSoftware /> },

  { path: "/software", component: <RecordSoftware /> },
  //Reportes pph
  { path: "/edit-report-pph/:theid", component: <EditReportePPH /> },

  { path: "/report-pph", component: <RecordReportePPH /> },
  //Reportes de Wl
  { path: "/edit-report-wl/:theid", component: <EditReporteWL /> },

  { path: "/report-wl", component: <RecordReporteWL /> },
  //Reportes de Percentage
  { path: "/edit-report-percentage/:theid", component: <EditReportePercentage /> },
,
  { path: "/report-percentage", component: <RecordReportePercentage /> },
  //Reportes de Review
  { path: "/edit-report-review/:theid", component: <EditReporteReview /> },

  { path: "/report-review", component: <RecordReporteReview /> },
  //Reportes de Affiliates
  { path: "/edit-report-affiliate/:theid", component: <EditReporteAffiliate /> },

  { path: "/report-affiliate", component: <RecordReporteAffiliate /> },
  //Marketing Reportes
  { path: "/edit-marketing-report/:theid", component: <EditMarketingReporte /> },

  { path: "/marketing-report", component: <RecordMarketingReporte /> },
  //Colaborador
  { path: "/edit-employee/:theid", component: <EditColaborador /> },
  { path: "/employee", component: <RecordColaborador /> },
  //Tiquetes
  { path: "/create-ticket", component: <CreateTiquete /> },
  { path: "/edit-ticket/:theid", component: <EditTiquete /> },
  { path: "/tickets", component: <RecordTiquete /> },
  //Usuarios
  { path: "/edit-user/:theid", component: <EditUsuario /> },

  { path: "/user", component: <RecordUsuario /> },
  //Templates
  { path: "/edit-template/:theid", component: <EditTemplate /> },

  { path: "/template", component: <RecordTemplate /> },
  //Freelance
  { path: "/edit-freelance/:theid", component: <EditFreelance /> },
  { path: "/freelance", component: <RecordFreelance /> },
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },

  // E-mail
  //{ path: "/inbox", component: <Inbox /> },
  // { path: "/read-email", component: <ReadEmail /> },
  { path: "/compose-email", component: <EmailCompose /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  // Authentication Inner Pages
  { path: "/auth-login", component: <Login1 /> },
  { path: "/auth-register", component: <Register1 /> },
  { path: "/auth-recoverpw", component: <RecoverPassword /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },

  // Utility Pages
  { path: "/pages-404", component: <Error404 /> },
  { path: "/pages-500", component: <Error500 /> },
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-comingsoon", component: <ComingSoon /> },
];

const MobileErrorPage = () => (
  <div className="page-content">
    <h2>Sorry, this page is only available on desktop devices.</h2>
  </div>
);


export { authProtectedRoutes,  publicRoutes };