import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListReporteAffiliate = props => {
    let userPuesto =   localStorage.getItem('puesto');
    let dis = [];
    if(userPuesto !== "Admin"){
        dis = "disabled"
    }
    return (
        <div className="row g-0 text-center bg-white bb py-3 min1500">
            <div className="col-2 lines">{props.nombre}</div>
            <div className="col-2 lines">{props.fecha}</div>
            <div className="col-2 lines">{props.departamento}</div>
            <div className="col-5 lines">{props.reporte}</div>
            <div className="col-1 lines"><Link role="link" className={dis} to={`/edit-report-affiliate/${props.id}`}>Edit</Link></div>
        </div>
    );
};
ListReporteAffiliate.propTypes = {
    reporteID: PropTypes.number,
    departamento: PropTypes.string,
    nombre: PropTypes.string,
    reporte: PropTypes.string,
    fecha: PropTypes.string,
};
export default ListReporteAffiliate;
