import React, { useContext } from "react";
import ListSuministro from "./listSuministro";
import { Context } from "../../store/appContext";
import { Row } from "reactstrap";

export default function RecordSuministro() {
    const { store } = useContext(Context);
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="g-0 overflow-scroll">
                    <Row className="row g-0 text-center bg-base text-white py-3 min1500">
                        <div className="col lines">Entity</div>
                        <div className="col lines">Cutoff Date</div>
                        <div className="col lines">Amount</div>
                        <div className="col lines">Service</div>
                        <div className="col lines">Account/Nice/ID</div>
                        <div className="col lines">Comment</div>
                    </Row>


                    {
                        store.suministros.map((item, index) => {
                            return (
                                <div key={index} className="col-12">
                                    <ListSuministro
                                        id={index}
                                        pagoID={item.pagoID}
                                        entidad={item.entidad}
                                        fechaCorte={item.fechaCorte}
                                        monto={item.monto}
                                        agencia={item.agencia}
                                        software={item.software}
                                        comentario={item.comentario}
                                        cuenta={item.cuenta}
                                    />
                                </div>
                            );
                        })}
                </Row>
            </div>
        </React.Fragment >
    )
}
