import React, { useContext } from "react";
import ListColaborador from "./listColaborador";
import { Context } from "../../store/appContext";
import { Row } from "reactstrap";

// let roy = window.localStorage.getItem("my_token", JSON.stringify());
// if (!roy) {
//     window.location.href = '/';
// } else {

//}

export default function RecordColaborador() {
    const { store } = useContext(Context);
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="g-0 overflow-scroll">
                    <Row className="row g-0 text-center bg-base text-white py-3 min1500">
                        <div className="col lines">Name</div>
                        <div className="col lines">ID card</div>
                        <div className="col lines">Phone</div>
                        <div className="col lines">Email</div>
                        <div className="col lines">Entry Date</div>
                        <div className="col lines">Job Position</div>
                        <div className="col lines">Working hours</div>
                        <div className="col lines">Salary</div>
                        <div className="col lines">Vacations</div>
                        <div className="col lines">Comments</div>
                    </Row>

                    {
                        store.colaborador.map((item, index) => {
                            return (
                                <div key={index} className="col-12">
                                    <ListColaborador
                                        id={index}
                                        colaboradorID={item.colaboradorID}
                                        nombre={item.nombre}
                                        cedula={item.cedula}
                                        telefono={item.telefono}
                                        email={item.email}
                                        fechaIngreso={item.fechaIngreso}
                                        puesto={item.puesto}
                                        horario={item.horario}
                                        salario={item.salario}
                                        vacaciones={item.vacaciones}
                                        comentarios={item.comentarios}
                                    />
                                </div>
                            );
                        })}
                </Row>
            </div>
        </React.Fragment >
    )
}
