import { useParams } from 'react-router-dom';
import React, { useState, useContext } from "react";
import { Context } from "../../store/appContext";
import { useNavigate } from 'react-router-dom';
import { Row } from "reactstrap";


export default function EditColaborador() {
    document.title = "Report | Edit Report Info";
    const navigate = useNavigate();
    const params = useParams();
    const { store } = useContext(Context);

    let userLog =   localStorage.getItem('Name');

    const [nombre, setNombre] = useState(store.colaborador[params.theid] && store.colaborador[params.theid].nombre);
    const [cedula, setCedula] = useState(store.colaborador[params.theid] && store.colaborador[params.theid].cedula);
    const [telefono, setTelefono] = useState(store.colaborador[params.theid] && store.colaborador[params.theid].telefono);
    const [email, setEmail] = useState(store.colaborador[params.theid] && store.colaborador[params.theid].email);
    const [fechaIngreso, setFechaIngreso] = useState(store.colaborador[params.theid] && store.colaborador[params.theid].fechaIngreso);
    const [puesto, setPuesto] = useState(store.colaborador[params.theid] && store.colaborador[params.theid].puesto);
    const [horario, setHorario] = useState(store.colaborador[params.theid] && store.colaborador[params.theid].horario);
    const [salario, setSalario] = useState(store.colaborador[params.theid] && store.colaborador[params.theid].salario);
    const [vacaciones, setVacaciones] = useState(store.colaborador[params.theid] && store.colaborador[params.theid].vacaciones);
    const [comentarios, setComentarios] = useState(store.colaborador[params.theid] && store.colaborador[params.theid].comentarios);

    const editColaborador = e => {
        e.preventDefault();
        const body = {
            cedula: cedula,
            nombre: nombre,
            telefono: telefono,
            email: email,
            fechaIngreso: fechaIngreso,
            puesto: puesto,
            horario: horario,
            salario: salario,
            vacaciones: vacaciones,
            comentarios: comentarios
        };
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/colaborador/" + store.colaborador[params.theid].colaboradorID, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                
                alert("The employee was update");
                navigate('/employee'); // Cambia '/ruta_destino' por la ruta a la que deseas redirigir
                actualizar();
            })
            .catch(err => console.log(err));
    };

    
    let actualizar = () => {
        setTimeout(() => {
            window.location.reload(true);
            console.log("Delayed for 1 second.");
          }, 1000);
    }

    const delet = e => {
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/colaborador/" + store.colaborador[params.theid].colaboradorID, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" }
        })
            .then(() => {
                alert("The employee was deleted");
                navigate('/employee'); // Cambia '/ruta_destino' por la ruta a la que deseas redirigir
                actualizar();
            })
            .catch(err => console.log(err));
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <form onSubmit={editColaborador} id="miFormNoticia">
                    <div className="card p-1">
                        <Row className="g-0">
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Name</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Name" aria-label="Name" defaultValue={store.colaborador[params.theid] && store.colaborador[params.theid].nombre} onChange={e => setNombre(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">ID card</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="ID card" aria-label="ID card" defaultValue={store.colaborador[params.theid] && store.colaborador[params.theid].cedula} onChange={e => setCedula(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Phone</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Phone" aria-label="Phone" defaultValue={store.colaborador[params.theid] && store.colaborador[params.theid].telefono} onChange={e => setTelefono(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Email</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Email" aria-label="Email" defaultValue={store.colaborador[params.theid] && store.colaborador[params.theid].email} onChange={e => setEmail(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Entry Date</div>
                                <input className="form-control selectInner shadow" type="date" placeholder="Entry Date" aria-label="Entry Date" defaultValue={store.colaborador[params.theid] && store.colaborador[params.theid].fechaIngreso} onChange={e => setFechaIngreso(e.target.value)} />
                            </div>
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Job Position</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Job Position" aria-label="Job Position" defaultValue={store.colaborador[params.theid] && store.colaborador[params.theid].puesto} onChange={e => setPuesto(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Working hours</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Working hours" aria-label="Working hours" defaultValue={store.colaborador[params.theid] && store.colaborador[params.theid].horario} onChange={e => setHorario(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Salary</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Salary" aria-label="Salary" defaultValue={store.colaborador[params.theid] && store.colaborador[params.theid].salario} onChange={e => setSalario(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Vacations</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Vacations" aria-label="Vacations" defaultValue={store.colaborador[params.theid] && store.colaborador[params.theid].vacaciones} onChange={e => setVacaciones(e.target.value)} />
                            </div>
                            <div className="col-6 p-1">
                                <div className="col-12 fw-bold">Comments</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Comments" aria-label="Comments" defaultValue={store.colaborador[params.theid] && store.colaborador[params.theid].comentarios} onChange={e => setComentarios(e.target.value)} />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-4 py-3">
                                <button type="submit" className="btn btn-success">Edit</button>
                            </div>
                            <div className="col-4 text-start p-3">
                                <button className="btn btn-danger" onClick={delet}>Delete</button>
                            </div>
                        </Row>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};