import React, { useEffect, useContext, useState } from "react";
import { Context } from "../../store/appContext";
import { Row } from "reactstrap";

import ReactApexChart from "react-apexcharts";

const DepositPerMontPercentage = () => {
    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth() + 1;
    const anoActual = fechaActual.getFullYear();

    // Función para agregar un cero delante del mes si es menor que 10
    const formatearMes = (mes) => {
        return mes < 10 ? `0${mes}` : mes;
    };

    const { store } = useContext(Context);

    const [chartData, setChartData] = useState({
        series: [
            {
                name: "Amount",
                type: "column",
                data: [],
            },
        ],
        options: {
            chart: {
                stacked: false,
                toolbar: {
                    show: false,
                },
            },
            stroke: {
                width: [0, 0.5, 1],
                curve: "smooth",
                dashArray: [0, 8, 5]
            },
            plotOptions: {
                bar: {
                    columnWidth: "5%",
                },
            },
            colors: ["#0ab39c", "rgba(212, 218, 221, 0.18)", "rgb(251, 77, 83)"],

            fill: {
                opacity: [0.85, 0.25, 1],
                gradient: {
                    inverseColors: false,
                    shade: "light",
                    type: "vertical",
                    opacityFrom: 0.85,
                    opacityTo: 0.55,
                    stops: [0, 100, 100, 100],
                },
            },

            markers: {
                size: 0,
            },
            legend: {
                offsetY: 11,
            },
            xaxis: {
                type: "month",
            },
            yaxis: {
                title: {
                    text: "Collection Amount",
                },
            },
            tooltip: {
                shared: true,
                typeof: "",
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "") {
                            return y.toFixed(0) + ""
                        }
                        return y
                    },
                },
            },
            grid: {
                borderColor: "#f1f1f1",
            },
        },
    });

    const [data, setData] = useState(formatearMes(mesActual)); // Inicializar data con el mes actual
    const [dataAno, setAnoData] = useState(anoActual); // Inicializar data con el año actual

    useEffect(() => {
        const montoConfirmed = [];
        store.cobroPercentage.forEach((i, index) => {
            if (i.fechaCorte.slice(0, 4) == dataAno && i.fechaCorte.slice(5, 7) == data) {
                const montoBTC = parseFloat(i.montoBTC);
                if (!isNaN(montoBTC)) {
                    montoConfirmed.push(montoBTC);
                }
                const montoDolar = parseFloat(i.monto_dolar);
                if (!isNaN(montoDolar)) {
                    montoConfirmed.push(montoDolar);
                }
            }
        });

        setChartData({
            ...chartData,
            series: [{ ...chartData.series[0], data: montoConfirmed }],
        });
    }, [store.cobroPercentage, mesActual, data, dataAno]);

    return (
        <React.Fragment>
            <Row>
                <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                        <h2>Collections per month in Percentage</h2>
                    </div>
                </div>
                <div className="col-lg-2">
                    <p>Year</p>
                    <input min={2023} max={2050} className="form-control selectInner shadow" type="number" placeholder={dataAno} onChange={e => setAnoData(e.target.value)}></input>
                </div>
                <div className="col-lg-2">
                    <p>Month</p>
                    <input min={1} max={12} className="form-control selectInner shadow" type="text" placeholder={data} onChange={e => setData(e.target.value)}></input>
                </div>

            </Row>
            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="line"
                height="250"
                stacked="true"
                className="apex-charts"
            />
        </React.Fragment>
    )
}

export default DepositPerMontPercentage;