import { useParams } from 'react-router-dom';
import React, { useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { Context } from "../../store/appContext";
import { Row } from "reactstrap";

export default function EditMarca() {
    document.title = "Company | Edit Brand Info";
    const navigate = useNavigate();
    const params = useParams();
    const { store } = useContext(Context);


    let userLog =   localStorage.getItem('Name');

    const [marca, setMarca] = useState(store.marcas[params.theid] && store.marcas[params.theid].marca);
    const [plataforma, setPlatform] = useState(store.marcas[params.theid] && store.marcas[params.theid].plataforma);
    const [comentario, setComentario] = useState(store.marcas[params.theid] && store.marcas[params.theid].comentario);
    const [facebook, setFacebook] = useState(store.marcas[params.theid] && store.marcas[params.theid].facebook);
    const [instagram, setInstagram] = useState(store.marcas[params.theid] && store.marcas[params.theid].instagram);
    const [twitter, setTwitter] = useState(store.marcas[params.theid] && store.marcas[params.theid].twitter);
    const [landing_pages, setLanding_pages] = useState(store.marcas[params.theid] && store.marcas[params.theid].landing_pages);
    const [usuarios, setUsuarios] = useState(store.marcas[params.theid] && store.marcas[params.theid].usuarios);
    const [youtube, setYoutube] = useState(store.marcas[params.theid] && store.marcas[params.theid].youtube);

    const crear = e => {
        e.preventDefault();
        const body = {
            facebook: facebook,
            instagram: instagram,
            twitter: twitter,
            marca: marca,
            plataforma: plataforma,
            landing_pages: landing_pages,
            usuarios: usuarios,
            youtube: youtube,
            comentario: comentario,
        };
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/marcas/" + store.marcas[params.theid].marcaID, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                
                alert("Brand  was updated");
                navigate('/brands'); // Cambia '/ruta_destino' por la ruta a la que deseas redirigir
                actualizar();
            })
            .catch(err => console.log(err));
    };

    let actualizar = () => {
        window.location.reload(true);

    }

    const delet = e => {
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/marcas/" + store.marcas[params.theid].marcaID, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" }
        })
            .then(() => {
                alert("Brand was Delet");
                navigate('/brands'); // Cambia '/ruta_destino' por la ruta a la que deseas redirigir
                actualizar();
            })
            .catch(err => console.log(err));
    };

    const type = ["SB", "PPH" , "Wl" ,"Percentage" , "Reviews" ,"Affiliate","Casino"];
    const plat = ["Buckeye","Jazz","PPHCR","Horizon"];
    return (
        <React.Fragment>
            <div className="page-content">
                <form onSubmit={crear} id="miFormNoticia">
                    <div className="card p-1">
                        <Row className="g-0">
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Brand</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Brand" aria-label="Brand" defaultValue={store.marcas[params.theid] && store.marcas[params.theid].marca} onChange={e => setMarca(e.target.value)} />
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Platform</div>
                                <select className="form-select" name="Type" aria-label="Type" defaultValue={store.marcas[params.theid] && store.marcas[params.theid].plataforma} onChange={e => setPlatform(e.target.value)} >
                                    {
                                        plat.map((index) => {
                                            return (
                                                <option key={index} name="ano" value={index} >{index}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Type</div>
                                <select className="form-select" name="Type" aria-label="Type" defaultValue={store.marcas[params.theid] && store.marcas[params.theid].comentario} onChange={e => setComentario(e.target.value)} >
                                    {
                                        type.map((index) => {
                                            return (
                                                <option key={index} name="ano" value={index} >{index}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </Row>
                        <Row className="g-0">

                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold"># Users</div>
                                <input className="form-control selectInner shadow" type="number" placeholder="# Users" aria-label="# Users" defaultValue={store.marcas[params.theid] && store.marcas[params.theid].usuarios} onChange={e => setUsuarios(e.target.value)} />
                            </div>

                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Facebook</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Facebook" aria-label="Facebook" defaultValue={store.marcas[params.theid] && store.marcas[params.theid].facebook} onChange={e => setFacebook(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Instagram</div>
                                <input className="form-control selectInner shadow" type="mail" placeholder="Instagram" aria-label="Instagram" defaultValue={store.marcas[params.theid] && store.marcas[params.theid].instagram} onChange={e => setInstagram(e.target.value)} />
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Twitter</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Twitter" aria-label="Twitter" defaultValue={store.marcas[params.theid] && store.marcas[params.theid].twitter} onChange={e => setTwitter(e.target.value)} />
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Landing Pages</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Landing Pages" aria-label="Landing Pages" defaultValue={store.marcas[params.theid] && store.marcas[params.theid].landing_pages} onChange={e => setLanding_pages(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">YouTube</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="YouTube" aria-label="YouTube" defaultValue={store.marcas[params.theid] && store.marcas[params.theid].youtube} onChange={e => setYoutube(e.target.value)} />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-4 py-3">
                                <button type="submit" className="btn btn-success">Edit</button>
                            </div>
                            <div className="col-4 text-start p-3">
                                <button className="btn btn-danger" onClick={delet} data-bs-dismiss="modal">Delete</button>
                            </div>
                        </Row>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};