import { useParams } from 'react-router-dom';
import React, { useState, useContext } from "react";
import { Context } from "../../store/appContext";
import { useNavigate } from 'react-router-dom';
import { Row } from "reactstrap";


export default function EditMarketingReporte() {
    document.title = "Marketing Report | Edit Report Info";
    const navigate = useNavigate();
    const params = useParams();
    const { store } = useContext(Context);

    const marcas = []
    store.marcas.forEach((i) => {
        marcas.push(i.marca); // Almacena la marca si cumple con la condición
    });

    let userLog =   localStorage.getItem('Name');

    const [usuario, setUsuario] = useState(store.marketingReportes[params.theid] && store.marketingReportes[params.theid].usuario);
    const [fecha, setFecha] = useState(store.marketingReportes[params.theid] && store.marketingReportes[params.theid].fecha);
    const [marca, setMarca] = useState(store.marketingReportes[params.theid] && store.marketingReportes[params.theid].marca);
    const [reporte, setReporte] = useState(store.marketingReportes[params.theid] && store.marketingReportes[params.theid].reporte);

    const crear = e => {
        e.preventDefault();
        const body = {
            fecha: fecha,
            usuario: usuario,
            marca: marca,
            reporte: reporte,
            comentario: "--",
        };
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/reporte_marketing/" + store.marketingReportes[params.theid].reporteID, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                
                alert("The report was update");
                navigate('/marketing-report'); // Cambia '/ruta_destino' por la ruta a la que deseas redirigir
                actualizar();
            })
            .catch(err => console.log(err));
    };

    
    let actualizar = () => {
        setTimeout(() => {
            window.location.reload(true);
            console.log("Delayed for 1 second.");
          }, 1000);
    }

    const delet = e => {
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/reporte_marketing/" + store.marketingReportes[params.theid].reporteID, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" }
        })
            .then(() => {
                alert("The report was deleted");
                navigate('/marketing-report'); // Cambia '/ruta_destino' por la ruta a la que deseas redirigir
                actualizar();
            })
            .catch(err => console.log(err));
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <form onSubmit={crear} id="miFormNoticia">
                    <div className="card p-1">
                        <Row className="g-0">
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Name</div>
                                <input className="form-control selectInner shadow" disabled type="text" placeholder="Name" aria-label="Name" defaultValue={store.marketingReportes[params.theid] && store.marketingReportes[params.theid].usuario} onChange={e => setUsuario(e.target.value)} />
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Date</div>
                                <input className="form-control selectInner shadow" type="date" placeholder="date" aria-label="date" defaultValue={store.marketingReportes[params.theid] && store.marketingReportes[params.theid].fecha} onChange={e => setFecha(e.target.value)} />
                            </div>
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Brand</div>
                                <select className="form-select" name="SB" aria-label="SB" defaultValue={store.marketingReportes[params.theid] && store.marketingReportes[params.theid].marca} onChange={e => setMarca(e.target.value)} >
                                    {
                                        marcas.map((index) => {
                                            return (
                                                <option key={index} name="sb" value={index} >{index}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-5 p-1">
                                <div className="col-12 fw-bold">Report <span className="badge bg-secondary">MAX 1500 characters</span></div>
                                <textarea maxLength="1500" rows={1} className="form-control selectInner shadow" type="text" placeholder="Report" aria-label="Report" defaultValue={store.marketingReportes[params.theid] && store.marketingReportes[params.theid].reporte} onChange={e => setReporte(e.target.value)} />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-4 py-3">
                                <button type="submit" className="btn btn-success">Edit</button>
                            </div>
                            <div className="col-4 text-start p-3">
                                <button className="btn btn-danger" onClick={delet} data-bs-dismiss="modal">Delete</button>
                            </div>
                        </Row>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};