import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const listAfiliado = props => {

    // let roy = window.localStorage.getItem("my_token", JSON.stringify());
    // if (!roy) {
    //     window.location.href = '/';
    // } else {

    // }

    return (
        <Link to={`/edit-affiliate/${props.id}`}>
            <div className="row g-0 text-center bg-white bb py-3 min1500">
                <div className="col lines">{props.nombre}</div>
                <div className="col lines">{props.telefono}</div>
                <div className="col lines">{props.email}</div>
                <div className="col lines">{props.marca}</div>
                <div className="col lines text-nowrap overfolw-hidden">{props.comentario}</div>
            </div>
        </Link>
    );
};
listAfiliado.propTypes = {
    agenteID: PropTypes.number,
    nombre: PropTypes.string,
    telefono: PropTypes.string,
    email: PropTypes.string,
    marca: PropTypes.string,
    comentario: PropTypes.string,
};
export default listAfiliado;
