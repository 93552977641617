import { useParams } from 'react-router-dom';
import React, { useState, useContext } from "react";
import { Context } from "../../store/appContext";
import { Row } from "reactstrap";

export default function EditSuministro() {
    document.title = "Company | Edit Company Info";

    const params = useParams();
    const { store } = useContext(Context);

    const marcasPPH = []
    store.marcas.forEach((i) => {
        if (i.comentario === "PPH") {
            marcasPPH.push(i.marca); // Almacena la marca si cumple con la condición
        }
    });


    const [entidad, setEntidad] = useState(store.suministros[params.theid] && store.suministros[params.theid].entidad);
    const [fechaCorte, setFechaCorte] = useState(store.suministros[params.theid] && store.suministros[params.theid].fechaCorte);
    const [monto, setMonto] = useState(store.suministros[params.theid] && store.suministros[params.theid].monto);
    const [software, setSoftware] = useState(store.suministros[params.theid] && store.suministros[params.theid].software);
    const [comentario, setComentario] = useState(store.suministros[params.theid] && store.suministros[params.theid].comentario);
    const [cuenta, setCuenta] = useState(store.suministros[params.theid] && store.suministros[params.theid].cuenta);
    const crear = e => {
        e.preventDefault();
        const body = {
            entidad: entidad,
            fechaCorte: fechaCorte,
            monto: monto,
            software: software,
            comentario: comentario,
            cuenta: cuenta
        };
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/pagos/" + store.suministros[params.theid].pagoID, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                
                alert("Supplie was updated");
                actualizar();
            })
            .catch(err => console.log(err));
    };

    let actualizar = () => {
        window.location.reload(true);

    }

    const delet = e => {
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/pagos/" + store.suministros[params.theid].pagoID, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" }
        })
            .then(() => {
                alert("Supplie was Delet");
                actualizar();
            })
            .catch(err => console.log(err));
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <form onSubmit={crear} id="miFormNoticia">
                    <div className="card p-1">
                        <Row className="g-0">
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Entity</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Entity" aria-label="Entity" defaultValue={store.suministros[params.theid] && store.suministros[params.theid].entidad} onChange={e => setEntidad(e.target.value)} />
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Cutoff Date</div>
                                <input className="form-control selectInner shadow" type="date" placeholder="Cutoff Date" aria-label="Cutoff Date" defaultValue={store.suministros[params.theid] && store.suministros[params.theid].fechaCorte} onChange={e => setFechaCorte(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Amount</div>
                                <input className="form-control selectInner shadow" type="number" placeholder="Amount" aria-label="Amount" defaultValue={store.suministros[params.theid] && store.suministros[params.theid].monto} onChange={e => setMonto(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Service</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Service" aria-label="Service" defaultValue={store.suministros[params.theid] && store.suministros[params.theid].software} onChange={e => setSoftware(e.target.value)} />
                            </div>
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Account/Nice/ID</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Account/Nice/ID" aria-label="Account/Nice/ID" defaultValue={store.suministros[params.theid] && store.suministros[params.theid].cuenta} onChange={e => setCuenta(e.target.value)} />
                            </div>
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Comment</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Comment" aria-label="Comment" defaultValue={store.suministros[params.theid] && store.suministros[params.theid].comentario} onChange={e => setComentario(e.target.value)} />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-4 py-3">
                                <button type="submit" className="btn btn-success">Edit</button>
                            </div>
                            <div className="col-4 text-start p-3">
                                <button className="btn btn-danger" onClick={delet} data-bs-dismiss="modal">Delete</button>
                            </div>
                        </Row>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};