import { useParams } from 'react-router-dom';
import React, { useState, useContext } from "react";
import { Context } from "../../store/appContext";
import { useNavigate } from 'react-router-dom';
import { Row } from "reactstrap";


export default function EditSoftware() {
    document.title = "Software | Edit Software Info";
    const navigate = useNavigate();
    const params = useParams();
    const { store } = useContext(Context);

    const [provedor, setProvedor] = useState(store.software[params.theid] && store.software[params.theid].provedor);
    const [nombre, setNombre] = useState(store.software[params.theid] && store.software[params.theid].nombre);
    const [pagos, setPagos] = useState(store.software[params.theid] && store.software[params.theid].pagos);
    const [tipo, setTipo] = useState(store.software[params.theid] && store.software[params.theid].tipo);
    const crear = e => {
        e.preventDefault();
        const body = {
            provedor: provedor,
            nombre: nombre,
            pagos: pagos,
            tipo: tipo
        };
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/software/" + store.software[params.theid].softwareID, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                
                alert("The software provider was update");
                navigate('/software'); // Cambia '/ruta_destino' por la ruta a la que deseas redirigir
                actualizar();
            })
            .catch(err => console.log(err));
    };

    
    let actualizar = () => {
        setTimeout(() => {
            window.location.reload(true);
            console.log("Delayed for 1 second.");
          }, 1000);
    }

    const delet = e => {
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/software/" + store.software[params.theid].softwareID, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" }
        })
            .then(() => {
                alert("The software provider was deleted");
                actualizar();
            })
            .catch(err => console.log(err));
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <form onSubmit={crear} id="miFormNoticia">
                    <div className="card p-1">
                        <Row className="g-0">
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Supplier</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Supplier" aria-label="Supplier" defaultValue={store.software[params.theid] && store.software[params.theid].provedor} onChange={e => setProvedor(e.target.value)} />
                            </div>
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Service</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Service" aria-label="Service" defaultValue={store.software[params.theid] && store.software[params.theid].nombre} onChange={e => setNombre(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Amount</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Amount" aria-label="Amount" defaultValue={store.software[params.theid] && store.software[params.theid].pagos} onChange={e => setPagos(e.target.value)} />
                            </div>
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Type of Payment (Month, Weekly , Annual, ...)</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Type of Payment" aria-label="Type of Payment" defaultValue={store.software[params.theid] && store.software[params.theid].tipo} onChange={e => setTipo(e.target.value)} />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-4 py-3">
                                <button type="submit" className="btn btn-success">Edit</button>
                            </div>
                            <div className="col-4 text-start p-3">
                                <button className="btn btn-danger" onClick={delet} data-bs-dismiss="modal">Delete</button>
                            </div>
                        </Row>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};