import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../store/appContext";
import { Row } from "reactstrap";
import ListMarca from "./listMarca";

export default function RecordMarca() {
    const { store } = useContext(Context);
    const [plataforma, setPlataforma] = useState("Buckeye");

    const type = ["PPH", "SB", "Wl", "Percentage", "Reviews", "Affiliate","Casino"];
    const plat = ["Buckeye", "Jazz", "PPHCR", "Horizon"];
    const [br, setBr] = useState("PPH");
    return (
        <React.Fragment>
            <div className="page-content">
                <Row>
                    <div className="col-lg-1"><h2>Platform</h2> </div>
                    <div className="col-lg-1 pb-2">
                        <select className="form-select" name="PLAT" aria-label="PLAT" onChange={e => setPlataforma(e.target.value)} >
                            {
                                plat.map((index) => {
                                    return (
                                        <option key={index} name="PLAT" value={index} >{index}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="col-lg-1"><h2>Type</h2> </div>
                    <div className="col-lg-2 pb-2">
                        <select className="form-select" name="Employee" aria-label="Employee" onChange={e => setBr(e.target.value)} >
                            {
                                type.map((index) => {
                                    return (
                                        <option key={index} name="type" value={index} >{index}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </Row>
                <Row className="g-0 overflow-scroll">
                    <Row className="row g-0 text-center bg-base text-white py-3 min1500">
                        <div className="col lines">Brand</div>
                        <div className="col lines">Platform</div>
                        <div className="col lines">Type</div>
                        <div className="col lines">Facebook</div>
                        <div className="col lines">Instagram</div>
                        <div className="col lines">Twitter</div>
                        <div className="col lines">You Tube</div>
                        <div className="col lines">Landing Page</div>
                        <div className="col lines"># Users</div>
                    </Row>
                    {
                        store.marcas.map((item, index) => {
                            if (item.comentario == br && item.plataforma == plataforma) {

                                return (
                                    <div key={index} className="col-12">
                                        <ListMarca
                                            id={index}
                                            marcaID={item.marcaID}
                                            marca={item.marca}
                                            plataforma={item.plataforma}
                                            comentario={item.comentario}
                                            facebook={item.facebook}
                                            instagram={item.instagram}
                                            twitter={item.twitter}
                                            youtube={item.youtube}
                                            landing_pages={item.landing_pages}
                                            usuarios={item.usuarios}
                                        />
                                    </div>
                                );
                            }
                        })}
                </Row>
            </div>
        </React.Fragment >
    )
}
