import { useParams, Redirect } from 'react-router-dom';
import React, { useState, useContext } from "react";
import { Context } from "../../store/appContext";
import { useNavigate } from 'react-router-dom';
import { Row } from "reactstrap";

export default function EditAgent() {
    document.title = "Agent | Edit Agent Info";
    const navigate = useNavigate();
    const params = useParams();
    const { store } = useContext(Context);

    const marcasPPH = []
    store.marcas.forEach((i) => {
        if (i.comentario === "PPH") {
            marcasPPH.push(i.marca); // Almacena la marca si cumple con la condición
        }
    });

    let userLog =   localStorage.getItem('Name');

    const [nombre, setNombre] = useState(store.agentes[params.theid] && store.agentes[params.theid].nombre);
    const [telefono, setTelefono] = useState(store.agentes[params.theid] && store.agentes[params.theid].telefono);
    const [email, setEmail] = useState(store.agentes[params.theid] && store.agentes[params.theid].email);
    const [agencia, setAgencia] = useState(store.agentes[params.theid] && store.agentes[params.theid].agencia);
    const [PPH_asignado, setPPH_asignado] = useState(store.agentes[params.theid] && store.agentes[params.theid].PPH_asignado);
    const [jugadores, setJugadores] = useState(store.agentes[params.theid] && store.agentes[params.theid].jugadores);
    const [extension_asignada, setExtension_asignada] = useState(store.agentes[params.theid] && store.agentes[params.theid].extension_asignada);
    const [precioPPH, setPrecioPPH] = useState(store.agentes[params.theid] && store.agentes[params.theid].precioPPH);
    const [comentario, setComentario] = useState(store.agentes[params.theid] && store.agentes[params.theid].comentario);
    const [precioLiveBetting, setPrecioLiveBetting] = useState(store.agentes[params.theid] && store.agentes[params.theid].precioLiveBetting);
    const [precioLivePropBuilder, setPrecioLivePropBuilder] = useState(store.agentes[params.theid] && store.agentes[params.theid].precioLivePropBuilder);
    const [porcentajeLiveDealer, setPorcentajeLiveDealer] = useState(store.agentes[params.theid] && store.agentes[params.theid].porcentajeLiveDealer);
    const [estado, setEstado] = useState(store.agentes[params.theid] && store.agentes[params.theid].estado);
    const crear = e => {
        e.preventDefault();
        const body = {
            nombre: nombre,
            telefono: telefono,
            email: email,
            agencia: agencia,
            PPH_asignado: PPH_asignado,
            precioPPH: precioPPH,
            precioLiveBetting: precioLiveBetting,
            precioLivePropBuilder: precioLivePropBuilder,
            porcentajeLiveDealer: porcentajeLiveDealer,
            jugadores: jugadores,
            extension_asignada: extension_asignada,
            comentario: comentario,
            estado: estado
        };
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/agentes/" + store.agentes[params.theid].agenteID, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                
                alert("Agent was updated");
                navigate('/agents');
                actualizar();
            })
            .catch(err => console.log(err));
    };

    let actualizar = () => {
        window.location.reload(true);

    }

    const delet = e => {
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/agentes/" + store.agentes[params.theid].agenteID, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" }
        })
            .then(() => {
                alert("Agent was Delet");
                actualizar();
                navigate('/agents');
            })
            .catch(err => console.log(err));
    };
    let state= ["Active","Pending","Close Account"]
    return (
        <React.Fragment>
            <div className="page-content">
                <form onSubmit={crear} id="miFormNoticia">
                    <div className="card p-1">
                        <Row className="g-0">
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Name</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Name" aria-label="Name" defaultValue={store.agentes[params.theid] && store.agentes[params.theid].nombre} onChange={e => setNombre(e.target.value)} />
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Phone</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Phone" aria-label="Phone" defaultValue={store.agentes[params.theid] && store.agentes[params.theid].telefono} onChange={e => setTelefono(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Email</div>
                                <input className="form-control selectInner shadow" type="mail" placeholder="Email" aria-label="Email" defaultValue={store.agentes[params.theid] && store.agentes[params.theid].email} onChange={e => setEmail(e.target.value)} />
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Agency</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Agency" aria-label="Agency" defaultValue={store.agentes[params.theid] && store.agentes[params.theid].agencia} onChange={e => setAgencia(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">PPH</div>
                                <select className="form-select" name="PPH" aria-label="PPH" defaultValue={store.agentes[params.theid] && store.agentes[params.theid].PPH_asignado} onChange={e => setPPH_asignado(e.target.value)} >
                                    {
                                        marcasPPH.map((index) => {
                                            return (
                                                <option key={index} name="ano" value={index} >{index}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">EXT</div>
                                <input className="form-control selectInner shadow" type="number" placeholder="EXT" aria-label="EXT" defaultValue={store.agentes[params.theid] && store.agentes[params.theid].extension_asignada} onChange={e => setExtension_asignada(e.target.value)} />
                            </div>
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Comment</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Comment" aria-label="Comment" defaultValue={store.agentes[params.theid] && store.agentes[params.theid].comentario} onChange={e => setComentario(e.target.value)} />
                            </div>
                        </Row>
                        <Row className="g-0">
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Players</div>
                                <input className="form-control selectInner shadow" type="number" placeholder="Players" aria-label="Players" defaultValue={store.agentes[params.theid] && store.agentes[params.theid].jugadores} onChange={e => setJugadores(e.target.value)} />
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Price PPH</div>
                                <input className="form-control selectInner shadow" type="number" placeholder="Price" aria-label="Price" defaultValue={store.agentes[params.theid] && store.agentes[params.theid].precioPPH} onChange={e => setPrecioPPH(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Live Betting Price</div>
                                <input className="form-control selectInner shadow" type="number" placeholder="Live Betting Price" aria-label="Live Betting Price" defaultValue={store.agentes[params.theid] && store.agentes[params.theid].precioLiveBetting} onChange={e => setPrecioLiveBetting(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Prop Builder Price</div>
                                <input className="form-control selectInner shadow" type="number" placeholder="Prop Builder Price" aria-label="Prop Builder Price" defaultValue={store.agentes[params.theid] && store.agentes[params.theid].precioLivePropBuilder} onChange={e => setPrecioLivePropBuilder(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Live Dealer %</div>
                                <input className="form-control selectInner shadow" type="number" placeholder="Live Dealer %" aria-label="Live Dealer %" defaultValue={store.agentes[params.theid] && store.agentes[params.theid].porcentajeLiveDealer} onChange={e => setPorcentajeLiveDealer(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">State</div>
                                <select className="form-select" name="State" aria-label="State" value={estado} onChange={e => setEstado(e.target.value)} >
                                    {
                                        state.map((index) => {
                                            return (
                                                <option key={index} name="state" value={index} >{index}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-4 py-3">
                                <button type="submit" className="btn btn-success">Save</button>
                            </div>
                            <div className="col-4 text-start p-3">
                                <button className="btn btn-danger" onClick={delet} data-bs-dismiss="modal">Delete</button>
                            </div>
                        </Row>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};