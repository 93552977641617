import React, { useContext } from "react";
import ListTemplate from "./listTemplate";
import { Context } from "../../store/appContext";
import { Row } from "reactstrap";

export default function RecordTemplate() {
    const { store } = useContext(Context);
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="g-0 overflow-scroll">
                    <Row className="row g-0 text-center bg-base text-white py-3 min1500">
                        <div className="col-3 lines">Name</div>
                        <div className="col-2 lines">Department</div>
                        <div className="col-3 lines">Application</div>
                        <div className="col-3 lines">File</div>
                        <div className="col-1 lines">Edit</div>
                    </Row>

                    {
                        store.templates.map((item, index) => {
                            return (
                                <div key={index} className="col-12">
                                    <ListTemplate
                                        id={index}
                                        templateID={item.templateID}
                                        nombre={item.nombre}
                                        departamento={item.departamento}
                                        uso={item.uso}
                                        archivo={item.archivo}
                                    />
                                </div>
                            );
                        })}
                </Row>
            </div>
        </React.Fragment >
    )
}
