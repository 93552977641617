import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListDepositoCasino = props => {

    return (
        <div className="row g-0 text-center bg-white bb py-3 min1500">
            <div className="col lines">{props.fecha}</div>
            <div className="col lines">{props.hora}</div>
            <div className="col lines">{props.jugador}</div>
            <div className="col lines">{props.comentario}</div>
            <div className="col lines">{props.metodo}</div>
            <div className="col lines">{props.cantidad}</div>
        </div>
    );
};
ListDepositoCasino.propTypes = {
    cobroID: PropTypes.number,
    fecha: PropTypes.string,
    hora: PropTypes.string,
    jugador: PropTypes.string,
    metodo: PropTypes.string,
    cantidad: PropTypes.string,
};
export default ListDepositoCasino;
