import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListDeposito = props => {

    // let roy = window.localStorage.getItem("my_token", JSON.stringify());
    // if (!roy) {
    //     window.location.href = '/';
    // } else {

    // }

    return (
        <div className="row g-0 text-center bg-white bb py-3 min1500">
            <div className="col lines">{props.fecha}</div>
            <div className="col lines">{props.hora}</div>
            <div className="col lines">{props.jugador}</div>
            <div className="col lines">{props.comentario}</div>
            <div className="col lines">{props.metodo}</div>
            <div className="col lines">{props.cantidad}</div>

        </div>
    );
};
ListDeposito.propTypes = {
    cobroID: PropTypes.number,
    fecha: PropTypes.string,
    hora: PropTypes.string,
    jugador: PropTypes.string,
    metodo: PropTypes.string,
    cantidad: PropTypes.string,
};
export default ListDeposito;
