import { useParams } from 'react-router-dom';
import React, { useState, useContext } from "react";
import { Context } from "../../store/appContext";
import { useNavigate } from 'react-router-dom';
import { Row } from "reactstrap";


export default function EditUsuario() {
    document.title = "User | Edit User Info";
    const navigate = useNavigate();
    const params = useParams();
    const { store } = useContext(Context);

    let userLog =   localStorage.getItem('Name');

    const [nombre, setNombre] = useState(store.users[params.theid] && store.users[params.theid].nombre);
    const [puesto, setPuesto] = useState(store.users[params.theid] && store.users[params.theid].puesto);
    const [password, setPassword] = useState("-");

    console.log("https://crmsbapp-7f82716e70c9.herokuapp.com/usuario/ " + store.users[params.theid].usuarioID)

    const editusers = e => {
        e.preventDefault();
        const body = {
            nombre: nombre,
            marcas: "-",
            puesto: puesto,
            email: "-",
            telefono: "-",
            comentarios: "-",
            password: password
        };
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/usuario/" + store.users[params.theid].usuarioID, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                
                alert("The Permission was update");
                navigate('/user'); // Cambia '/ruta_destino' por la ruta a la que deseas redirigir
                actualizar();
            })
            .catch(err => {console.log(err);console.log(body)});
    };

    
    let actualizar = () => {
        setTimeout(() => {
            window.location.reload(true);
          }, 1000);
    }

    const delet = e => {
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/usuario/" + store.users[params.theid].usuarioID,  {
            method: "DELETE",
            headers: { "Content-Type": "application/json" }
        })
            .then(() => {
                alert("The Permission was deleted");
                navigate('/user'); // Cambia '/ruta_destino' por la ruta a la que deseas redirigir
                actualizar();
            })
            .catch(err => console.log(err));
    };

    const puestos =["Admin","Marketing","Cs","Collections"]
    return (
        <React.Fragment>
            <div className="page-content">
                <form onSubmit={editusers} id="miFormNoticia">
                    <div className="card p-1 overflow-scroll">
                        <Row className="g-0 min1500">
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Name</div>
                                <input disabled className="form-control selectInner shadow" type="text" placeholder="Name" aria-label="Name" defaultValue={store.users[params.theid] && store.users[params.theid].nombre} onChange={e => setNombre(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">User permissions</div>
                                <select className="form-select" name="User permissions" aria-label="User permissions" defaultValue={store.users[params.theid] && store.users[params.theid].puesto} onChange={e => setPuesto(e.target.value)} >
                                    {
                                        puestos.map((index) => {
                                            return (
                                                <option key={index} name="User permissions" value={index} >{index}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Password</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Password" aria-label="Password" defaultValue={store.users[params.theid] && store.users[params.theid].password} onChange={e => setPassword(e.target.value)} />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-4 py-3">
                                <button type="submit" className="btn btn-success">Edit</button>
                            </div>
                            <div className="col-4 text-start p-3">
                                <button className="btn btn-danger" onClick={delet}>Delete</button>
                            </div>
                        </Row>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};