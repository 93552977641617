import { useParams } from 'react-router-dom';
import React, { useState, useContext } from "react";
import { Context } from "../../store/appContext";
import { useNavigate } from 'react-router-dom';
import { Row } from "reactstrap";


export default function EditReporteAffiliate() {
    document.title = "Report | Edit Report Info";
    const navigate = useNavigate();
    const params = useParams();
    const { store } = useContext(Context);

    const [nombre, setNombre] = useState(store.reportesAffiliate[params.theid] && store.reportesAffiliate[params.theid].nombre);
    const [departamento, setDepartamento] = useState(store.reportesAffiliate[params.theid] && store.reportesAffiliate[params.theid].departamento);
    const [reporte, setReporte] = useState(store.reportesAffiliate[params.theid] && store.reportesAffiliate[params.theid].reporte);
    const [fecha] = useState(store.reportesAffiliate[params.theid] && store.reportesAffiliate[params.theid].fecha);
    const crear = e => {
        e.preventDefault();
        const body = {
            departamento: departamento,
            nombre: nombre,
            reporte: reporte,
            fecha:fecha
        };
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/reportesAfiliate/" + store.reportesAffiliate[params.theid].reporteID, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                
                alert("The report was update");
                navigate('/report-affiliate'); // Cambia '/ruta_destino' por la ruta a la que deseas redirigir
                actualizar();
            })
            .catch(err => console.log(err));
    };

    
    let actualizar = () => {
        setTimeout(() => {
            window.location.reload(true);
            console.log("Delayed for 1 second.");
          }, 1000);
    }

    const delet = e => {
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/reportesAfiliate/" + store.reportesAffiliate[params.theid].reporteID, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" }
        })
            .then(() => {
                alert("The report was deleted");
                navigate('/report-affiliate'); // Cambia '/ruta_destino' por la ruta a la que deseas redirigir
                actualizar();
            })
            .catch(err => console.log(err));
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <h2>Edit Affiliate Report</h2>
                <form onSubmit={crear} id="miFormNoticia">
                    <div className="card p-1">
                        <Row className="g-0">
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Name</div>
                                <input className="form-control selectInner shadow" disabled type="text" placeholder="Name" aria-label="Name" defaultValue={store.reportesAffiliate[params.theid] && store.reportesAffiliate[params.theid].nombre} onChange={e => setNombre(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Department</div>
                                <input className="form-control selectInner shadow" type="text" disabled placeholder="Department" aria-label="Department" defaultValue={store.reportesAffiliate[params.theid] && store.reportesAffiliate[params.theid].departamento} onChange={e => setDepartamento(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Date</div>
                                <input className="form-control selectInner shadow" disabled type="text" placeholder="Date" aria-label="Date" defaultValue={store.reportesAffiliate[params.theid] && store.reportesAffiliate[params.theid].fecha}/>
                            </div>
                            <div className="col-6 p-1">
                                <div className="col-12 fw-bold">Report <span className="badge bg-secondary">MAX 1500 characters</span></div>
                                <textarea maxLength="1500" rows={1} className="form-control selectInner shadow" type="text" placeholder="Report" aria-label="Report" defaultValue={store.reportesAffiliate[params.theid] && store.reportesAffiliate[params.theid].reporte} onChange={e => setReporte(e.target.value)} />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-4 py-3">
                                <button type="submit" className="btn btn-success">Edit</button>
                            </div>
                            <div className="col-4 text-start p-3">
                                <button className="btn btn-danger" onClick={delet} data-bs-dismiss="modal">Delete</button>
                            </div>
                        </Row>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};