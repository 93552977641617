import React from "react";

import { Container } from "reactstrap";
//collections
import CollectionsPPH from "./collectionsPPH";
import CollectionsWL from "./collectionsWl";
import CollectionsPercentage from "./collectionsPercentage";
import CollectionsReview from "./collectionsReview";
import CollectionsAffiliate from "./collectionsAffiliate";
import OverView from "./OverView";
//reports
import PphReports from "./pphReports";
import WLReports from "./wlReports";
import PercentageReports from "./percentageReports";
import AffialteReports from "./affiliateReports";
import MarketingReports from "./marketingReport";
import ReviewReports from "./reviewReports";
import RecordIngresos from "../ingresos/recordIngresos";
import Tiquete from "./tiquetes";
import BalanzeSB from "./balanzeSB";
import BalanzeCasino from "./balanzeCasino";

import Empresa from "./empresas";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";


const Dashboard = () => {
  document.title = "Dashboard | CRM SB - React Admin & Dashboard Template";

  const userPuesto = localStorage.getItem("puesto");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="CRM SB" breadcrumbItem="CRM" />
          {/* User Panel Charts */}

          {userPuesto === 'Admin' && (
            <>
              <Empresa />
              <CollectionsPPH />
              <CollectionsWL />
              <CollectionsPercentage />
              <CollectionsReview />
              <CollectionsAffiliate />
              <OverView />
              <BalanzeSB />
              <BalanzeCasino/>
              {/* Tiquete */}
              <Tiquete />
              {/* reports Table */}
              <PphReports />
              <WLReports />
              <PercentageReports />
              <AffialteReports />
              <ReviewReports />
              <MarketingReports />
              {/* marcas de ingreso */}
              <RecordIngresos />
            </>
          )}
          {userPuesto === 'Marketing' && (
            <>
              <MarketingReports />
            </>
          )}
          {userPuesto === 'Cs' && (
            <>
              <PphReports />
              <WLReports />
              <PercentageReports />
              <AffialteReports />
              <ReviewReports />
            </>
          )}
          {userPuesto === 'Collections' && (
            <>
              <CollectionsPPH />
              <CollectionsWL />
              <CollectionsPercentage />
              <CollectionsReview />
              <CollectionsAffiliate />
            </>
          )}
          {userPuesto === 'SB' && (
            <>
            </>
          )}

          {/* se pueden agregar mas puestos */}

        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
