import React, { useContext } from "react";
import { Context } from "../../store/appContext";
import ListTiquete from "./listTiquete";
import { Row } from "reactstrap";
const RecordTiquete = () => {
  const { store } = useContext(Context);
  let userLog = localStorage.getItem('Name');
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="card overflow-scroll">
          <Row className="row g-0 text-center bg-base text-white min1500">
            <div className="col">
              <p><strong>Sender: </strong></p>
            </div>
            <div className="col">
              <p><strong>Recipient: </strong></p>
            </div>
            <div className="col">
              <p><strong>Content: </strong></p>
            </div>
            <div className="col">
              <p><strong>Date: </strong></p>
            </div>
            <div className="col">
              <p><strong>State: </strong></p>
            </div>
            <div className="col">
              <p><strong>Edit: </strong></p>
            </div>
          </Row>
          {store.tiquetes.map((item, index) => {
            if (item.recipient ==  userLog || item.sender == userLog) {

              return (
                <div key={index}>
                  <ListTiquete
                    id={index}
                    tiqueteID={item.tiqueteID}
                    sender={item.sender}
                    recipient={item.recipient}
                    content={item.content}
                    fecha={item.fecha}
                    estado={item.estado}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default RecordTiquete;
