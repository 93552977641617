import React from 'react';
import DepositPerMontPPH from './depositsPermonthPPH';
import DepositPerMonthWL from './depositsPermonthWL';
import DepositPerMontPercentage from './depositsPermonthPercentage';
import DepositPerMontReview from './depositsPermonthReview';
import DepositPerMontAffiliate from './depositPermonthAffiliate';
import LineColumnAreaSB from './depositsPermonthSB';
import BalanzeSB from './balanzeSB';

import {
    Card,
    CardBody,
    Col,
    Row,
} from "reactstrap";

const OverView = () => {
    return (
        <React.Fragment>
            <Row>
                <Col xl={6}>
                    <Card>
                        <CardBody>
                            <div>
                                <DepositPerMontPPH />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={6}>
                    <Card>
                        <CardBody>
                            <div>
                                <DepositPerMonthWL />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={6}>
                    <Card>
                        <CardBody>
                            <div>
                                <DepositPerMontPercentage />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={6}>
                    <Card>
                        <CardBody>
                            <div>
                                <DepositPerMontReview />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={6}>
                    <Card>
                        <CardBody>
                            <div>
                                <DepositPerMontAffiliate />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Col xl={12}>
                {/* <Card>
                    <CardBody>
                        <div>
                            <BalanzeSB />
                        </div>
                    </CardBody>
                </Card> */}
                {/* <Card>
                    <CardBody>
                        <div>
                            <LineColumnAreaSB />
                        </div>
                    </CardBody>
                </Card> */}
            </Col>
        </React.Fragment>
    );
};

export default OverView;