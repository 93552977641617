import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListSuministro = props => {

    // let roy = window.localStorage.getItem("my_token", JSON.stringify());
    // if (!roy) {
    //     window.location.href = '/';
    // } else {

    // }

    return (
        <div className="row g-0 text-center bg-white bb py-3 min1500">
            <div className="col lines">{props.entidad}</div>
            <div className="col lines">{props.fechaCorte}</div>
            <div className="col lines">{props.monto}</div>
            <div className="col lines">{props.software}</div>
            <div className="col lines">{props.cuenta}</div>
            <div className="col lines">{props.comentario}</div>
        </div>
    );
};
ListSuministro.propTypes = {
    pagoID: PropTypes.number,
    entidad: PropTypes.string,
    fechaCorte: PropTypes.string,
    monto: PropTypes.string,
    software: PropTypes.string,
    cuenta: PropTypes.string,
    comentario: PropTypes.string,
};
export default ListSuministro;
