import React, { useContext } from "react";
import ListSoftware from "./listSoftware";
import { Context } from "../../store/appContext";
import { Row } from "reactstrap";

export default function RecordSoftware() {
    const { store } = useContext(Context);
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="g-0 overflow-scroll">
                    <Row className="row g-0 text-center bg-base text-white py-3 min1500">
                        <div className="col-3 lines">Supplier</div>
                        <div className="col-3 lines">Service</div>
                        <div className="col-2 lines">Amount</div>
                        <div className="col-2 lines">Type of Payment</div>
                    </Row>

                    {
                        store.software.map((item, index) => {
                            return (
                                <div key={index} className="col-12">
                                    <ListSoftware
                                        id={index}
                                        softwareID={item.softwareID}
                                        provedor={item.provedor}
                                        nombre={item.nombre}
                                        pagos={item.pagos}
                                        tipo={item.tipo}
                                    />
                                </div>
                            );
                        })}
                </Row>
            </div>
        </React.Fragment >
    )
}
