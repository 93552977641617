import React, { useState, useEffect, useContext } from "react";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { Context } from "../../store/appContext";
import { useNavigate } from 'react-router-dom';

const Login = props => {
  document.title = "Login | CRM SB - React Admin & Dashboard Template";
  useEffect(() => {
    document.body.className = "bg-pattern";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });
  const { store } = useContext(Context);
  
  const ahora = new Date();
  //se obtiene fecha de marca
  const ano = ahora.getFullYear(); // Obtiene el año 
  const mes = ahora.getMonth() + 1; // Obtiene el mes
  const dia = ahora.getDate() ; // Obtiene el dia
  
  //se obtine hora para marca
  const horaActual = ahora.getHours(); // Obtiene la hora actual (formato 24 horas)
  const minutoActual = ahora.getMinutes(); // Obtiene el minuto actual
  

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const userLog = []
  const username = []
  
  store.users.forEach((i) => {
    if (i.email === email) {
      userLog.push(i.puesto); // Almacena la puesto para local store
      username.push(i.nombre); // Almacena la puesto para local store
    }
  });
  //nombre de login y hora de marca

  const nombre = username;
  const marcas =  horaActual + ':' + minutoActual;

  const formatearNumero = (numero) => {
    return numero < 10 ? `0${numero}` : numero;
  };

  const fecha = ano + "/" + formatearNumero(mes) + "/" + formatearNumero(dia);

  const marca = async () => {
    try {
      const response = await fetch('https://crmsbapp-7f82716e70c9.herokuapp.com/ingreso', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          marca: marcas, 
          fecha: fecha, 
          nombre: nombre 
        }),
      });

    } catch (error) {
      console.error('Error:', error.message);
      Er();
      // Manejo de errores, como mostrar un mensaje de error al usuario
    }
  };



  const enviar = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://crmsbapp-7f82716e70c9.herokuapp.com/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error('Credenciales inválidas');
        Er();
      }

      const data = await response.json();
      const token = data.token;
      marca();
      // Guarda el token en el almacenamiento local
      localStorage.setItem('token', token);
      localStorage.setItem('puesto', userLog);
      localStorage.setItem('Name', username);

      // Redirige a la tabla de usuarios después del inicio de sesión exitoso
      navigate('/dashboard');

    } catch (error) {
      console.error('Error:', error.message);
      Er();
      // Manejo de errores, como mostrar un mensaje de error al usuario
    }
  };

  const [showErro, setShowError] = useState("d-none");

  const Er =()=>{
    setShowError("d-block")
  }

  return (
    <React.Fragment>
      <div className="bg-overlay"></div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={8} xl={4}>
              <Card>
                <CardBody className="p-4">
                  <div className="col-12 mx-auto rounded p-3 shadow-login" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div className="col-12 text-center">
                      <h2 className="col-12 text-dark text_shadow">Login</h2>
                    </div>
                    <form onSubmit={enviar}>
                      <div className="input-group mb-3 shadow ">
                        <span className="input-group-text" id="basic-addon1">@</span>
                        <input
                          id="mail"
                          onChange={e => setEmail(e.target.value)}
                          name="mail"
                          type="text"
                          className="form-control "
                          placeholder="Mail"
                          aria-label="Mail"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <div className="input-group mb-3 shadow">
                        <span className="input-group-text" id="basic-addon1">
                          <i className="fas fa-key" />
                        </span>
                        <input
                          type="password"
                          onChange={e => setPassword(e.target.value)}
                          id="password"
                          name="password"
                          className="form-control "
                          placeholder="Password"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <div className={showErro + " col-12 text-center py-2"}>
                        <span className="text-danger fw-bold ">Wrong Emal or  Password</span>
                      </div>
                      <div className="col-12 text-center">
                        <button type="submit" className="btn btn-primary waves-effect waves-light shadow-st">
                          Login
                        </button>
                      </div>
                    </form>

                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="text-white-50">
                  © {new Date().getFullYear()}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
