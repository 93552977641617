import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListSoftware = props => {

    // let roy = window.localStorage.getItem("my_token", JSON.stringify());
    // if (!roy) {
    //     window.location.href = '/';
    // } else {

    // }

    return (
        <div className="row g-0 text-center bg-white bb py-3 min1500">
            <div className="col-3 lines">{props.provedor}</div>
            <div className="col-3 lines">{props.nombre}</div>
            <div className="col-2 lines">{props.pagos}</div>
            <div className="col-2 lines">{props.tipo}</div>
        </div>
    );
};
ListSoftware.propTypes = {
    softwareID: PropTypes.number,
    provedor: PropTypes.string,
    nombre: PropTypes.string,
    pagos: PropTypes.string,
    tipo: PropTypes.string,
};
export default ListSoftware;
