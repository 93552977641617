import React from "react";
import Routes from "./Routes/index";
import injectContext from "./store/appContext";
// Import Scss
import './assets/scss/theme.scss';
// import ChatComponent from "./components/Chat/chat";

function App() {
  return (
    <React.Fragment>
      <Routes />
      {/* <ChatComponent/> */}
    </React.Fragment>
  );
}

export default injectContext(App);
