import React, { useContext, useState } from "react";

import ListReporteAffiliate from "./listReporteAffiliate";
import { Context } from "../../store/appContext";
import { Row } from "reactstrap";

export default function RecordReporteAffiliate() {
    const { store } = useContext(Context);
    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth() + 1;
    const anoActual = fechaActual.getFullYear();

    // Función para agregar un cero delante del mes si es menor que 10
    const formatearMes = (mes) => {
        return mes < 10 ? `0${mes}` : mes;
    };


    const [mesReportCs, setMesReportCs] = useState(formatearMes(mesActual));
    const [anoReportCs, setAnoReportCs] = useState(anoActual);

    return (
        <React.Fragment>
            <div className="page-content">
                <h2>Affiliates Reports</h2>
                <Row className="py-4">
                    <div className="col-lg-2">
                        <p>Year</p>
                        <input min={2023} max={2050} className="form-control selectInner shadow" type="number" placeholder={anoReportCs} onChange={e => setAnoReportCs(e.target.value)}></input>
                    </div>
                    <div className="col-lg-2">
                        <p>Month</p>
                        <input min={1} max={12} className="form-control selectInner shadow" type="number" placeholder={mesReportCs} onChange={e => setMesReportCs(e.target.value)}></input>
                    </div>
                </Row>
                <Row className="g-0 overflow-scroll">
                    <Row className="row g-0 text-center bg-base text-white py-3 min1500">
                        <div className="col-2 lines">Name</div>
                        <div className="col-2 lines">Date</div>
                        <div className="col-2 lines">Department</div>
                        <div className="col-5 lines">Report</div>
                        <div className="col-1 lines">Edit</div>
                    </Row>
                    {
                        store.reportesAffiliate.map((item, index) => {
                            if (item.fecha.slice(0, 4) == anoReportCs && item.fecha.slice(5, 7) == mesReportCs) {
                                return (
                                    <div key={index} className="col-12">
                                        <ListReporteAffiliate
                                            id={index}
                                            reporteID={item.reporteID}
                                            nombre={item.nombre}
                                            departamento={item.departamento}
                                            reporte={item.reporte}
                                            fecha={item.fecha}
                                        />
                                    </div>
                                );
                            }
                        })}
                </Row>
            </div>
        </React.Fragment >
    )
}
