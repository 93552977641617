import React, { useContext } from "react";
import ListCobrosPercentage from './listCobrosPercentage';
import { Context } from "../../store/appContext";
import { Row } from "reactstrap";

export default function RecordCobrosPercentage() {
    const { store } = useContext(Context);
    return (
        <React.Fragment>
            <div className="page-content">
            <h2>Percentage Collections</h2>
                <Row className="g-0 overflow-scroll">
                    <Row className="row g-0 text-center bg-base text-white py-3 min1500">
                        <div className="col lines">Cut Date</div>
                        <div className="col lines">Hour</div>
                        <div className="col lines">Agency</div>
                        <div className="col lines">Method</div>
                        <div className="col lines">BTC amount</div>
                        <div className="col lines">Dollar Amount</div>
                        <div className="col lines">Brand</div>
                        <div className="col lines">State</div>
                        <div className="col lines">User</div>
                        <div className="col lines">Comment</div>
                    </Row>
                    {
                        store.cobroPercentage.map((item, index) => {
                            return (
                                <div key={index} className="col-12">
                                    <ListCobrosPercentage
                                        id={index}
                                        cobroID={item.cobroID}
                                        fechaCorte={item.fechaCorte}
                                        hora={item.hora}
                                        agente={item.agente}
                                        metodo={item.metodo}
                                        montoBTC={item.montoBTC}
                                        monto_dolar={item.monto_dolar}
                                        marca={item.marca}
                                        estado={item.estado}
                                        usuario={item.usuario}
                                        comentario={item.comentario}
                                    />
                                </div>
                            );
                        })}
                </Row>
            </div>
        </React.Fragment >
    )
}
