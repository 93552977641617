import { useParams } from 'react-router-dom';
import React, { useState, useContext } from "react";
import { Context } from "../../store/appContext";
import { useNavigate } from 'react-router-dom';
import { Row } from "reactstrap";

export default function EditTiquete() {
    document.title = "Ticket | Edit Ticket Info";
    const navigate = useNavigate();
    const params = useParams();
    const { store } = useContext(Context);

    const fechaActual = new Date();
    const dia = fechaActual.getDate();
    const mes = fechaActual.getMonth() + 1; // Los meses empiezan desde 0, por eso se suma 1
    const ano = fechaActual.getFullYear();
    
    // Función para agregar un cero delante del número si es menor que 10
    const formatearNumero = (numero) => {
      return numero < 10 ? `0${numero}` : numero;
    };
    
    // Formatear día y mes
    const diaFormateado = formatearNumero(dia);
    const mesFormateado = formatearNumero(mes);
    
    let date = ano + "-" + mesFormateado + '-' + diaFormateado;


    let userLog =   localStorage.getItem('Name');

    const [recipient, setRecipient] = useState(store.tiquetes[params.theid] && store.tiquetes[params.theid].recipient);
    const [estado, setEstado] = useState(store.tiquetes[params.theid] && store.tiquetes[params.theid].estado);
    const [comentarios, setComentarios] = useState(store.tiquetes[params.theid] && store.tiquetes[params.theid].content);
    const [fecha, setFecha] = useState(store.tiquetes[params.theid] && store.tiquetes[params.theid].fecha);


    const actualizar = () => {
        window.location.reload(true);
    }
    const crear = e => {
        e.preventDefault();
        const body = {
            sender: userLog,
            estado: estado,
            recipient: recipient,
            content: comentarios,
            estado: estado,
            fecha: date
        };
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/tiquetes/" + store.tiquetes[params.theid].tiqueteID, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                
                alert("Company was updated");
                navigate('/tickets');
                actualizar();
            })
            .catch(err => console.log(err));
    };

    const delet = e => {
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/tiquetes/" + store.tiquetes[params.theid].tiqueteID, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" }
        })
            .then(() => {
                alert("Company was Delet");
                actualizar();
                navigate('/company');
            })
            .catch(err => console.log(err));
    };
    let state = ["Open" , "Close"]
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="card p-2 overflow-scroll">
                    <h1>Edit Ticket</h1>
                    <form onSubmit={crear} >
                        <Row className="g-0 min1500">
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">User</div>
                                <input className="form-control selectInner shadow" disabled type="text" placeholder="User" defaultValue={store.tiquetes[params.theid] && store.tiquetes[params.theid].recipient} aria-label="User" onChange={e => setRecipient(e.target.value)} />
                            </div>
                            <div className="col-4 p-1">
                                <div className="col-12 fw-bold">Content </div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Content" defaultValue={store.tiquetes[params.theid] && store.tiquetes[params.theid].content} aria-label="Content" onChange={e => setComentarios(e.target.value)} />
                            </div>

                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Date </div>
                                <input disabled className="form-control selectInner shadow" type="text" placeholder={date} onChange={e => setFecha(e.target.value)} />
                            </div>

                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">State </div>
                                <select className="form-select" name="State" defaultValue={store.tiquetes[params.theid] && store.tiquetes[params.theid].estado} aria-label="State" onChange={e => setEstado(e.target.value)} >
                                    {
                                        state.map((index) => {
                                            return (
                                                <option key={index} name="State" value={index} >{index}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-4 py-3">
                                <button type="submit" className="btn btn-success">Save</button>
                            </div>
                            <div className="col-4 text-start p-3">
                                <button className="btn btn-danger" onClick={delet} data-bs-dismiss="modal">Delete</button>
                            </div>
                        </Row>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
};