import { useParams } from 'react-router-dom';
import React, { useState, useContext } from "react";
import { Context } from "../../store/appContext";
import { useNavigate } from 'react-router-dom';
import { Row } from "reactstrap";


export default function EditFreelance() {
    document.title = "User | Edit User Info";
    const navigate = useNavigate();
    const params = useParams();
    const { store } = useContext(Context);

    const [nombre, setNombre] = useState(store.freelance[params.theid] && store.freelance[params.theid].nombre);
    const [cedula, setCedula] = useState(store.freelance[params.theid] && store.freelance[params.theid].cedula);
    const [correo, setCorreo] =  useState(store.freelance[params.theid] && store.freelance[params.theid].correo);
    const [telefono, setTelefono] =  useState(store.freelance[params.theid] && store.freelance[params.theid].telefono);
    const [monto, setMonto] =  useState(store.freelance[params.theid] && store.freelance[params.theid].monto);
    const [contrato, setContrato] =  useState(store.freelance[params.theid] && store.freelance[params.theid].contrato);
    const [tipoContrato, setTipoContrato] =  useState(store.freelance[params.theid] && store.freelance[params.theid].tipoContrato);


    const editfreelance = e => {
        e.preventDefault();
        const body = {
            nombre: nombre,
            cedula: cedula,
            correo: correo,
            telefono: telefono,
            monto: monto,
            contrato: contrato,
            tipoContrato: tipoContrato
        };
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/freelance/" + store.freelance[params.theid].freelanceID, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                alert("The Freelance was update");
                navigate('/freelance'); // Cambia '/ruta_destino' por la ruta a la que deseas redirigir
                actualizar();
            })
            .catch(err => {console.log(err);console.log(body)});
    };

    
    let actualizar = () => {
        setTimeout(() => {
            window.location.reload(true);
          }, 1000);
    }

    const delet = () => {
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/freelance/" + store.freelance[params.theid].freelanceID,  {
            method: "DELETE",
            headers: { "Content-Type": "application/json" }
        })
            .then(() => {
                alert("The Freelance was deleted");
                navigate('/freelance'); // Cambia '/ruta_destino' por la ruta a la que deseas redirigir
                actualizar();
            })
            .catch(err => console.log(err));
    };

    const tiposContrato = ["Weekly ", "Biweekly", "Monthly ", "Annual", "Per Task"];
    return (
        <React.Fragment>
            <div className="page-content">
                <form onSubmit={editfreelance} id="miFormNoticia">
                    <div className="card p-1">
                        <Row className="g-0">
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Name</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Name" aria-label="Name" defaultValue={store.freelance[params.theid] && store.freelance[params.theid].nombre} onChange={e => setNombre(e.target.value)} />
                            </div>
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">ID Card</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="ID Card" aria-label="ID Card" defaultValue={store.freelance[params.theid] && store.freelance[params.theid].cedula} onChange={e => setCedula(e.target.value)} />
                            </div>
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Email</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Email" aria-label="Email" defaultValue={store.freelance[params.theid] && store.freelance[params.theid].correo} onChange={e => setCorreo(e.target.value)} />
                            </div>
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Phone</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Phone" aria-label="Phone" defaultValue={store.freelance[params.theid] && store.freelance[params.theid].telefono} onChange={e => setTelefono(e.target.value)} />
                            </div>
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Ammount</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Ammount" aria-label="Ammount" defaultValue={store.freelance[params.theid] && store.freelance[params.theid].monto} onChange={e => setMonto(e.target.value)} />
                            </div>
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Contract</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Contract" aria-label="Contract" defaultValue={store.freelance[params.theid] && store.freelance[params.theid].console} onChange={e => setContrato(e.target.value)} />
                            </div>
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Contract Type</div>
                                <select className="form-select" name="Contract Type" aria-label="Contract Type" value={tipoContrato} onChange={e => setTipoContrato(e.target.value)} >
                                    {
                                        tiposContrato.map((index) => {
                                            return (
                                                <option key={index} name="type" value={index} >{index}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-4 py-3">
                                <button type="submit" className="btn btn-success">Edit</button>
                            </div>
                            <div className="col-4 text-start p-3">
                                <button className="btn btn-danger" onClick={delet}>Delete</button>
                            </div>
                        </Row>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};