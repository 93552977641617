import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListCobros = props => {

    // let roy = window.localStorage.getItem("my_token", JSON.stringify());
    // if (!roy) {
    //     window.location.href = '/';
    // } else {

    // }

    return (
        <div className="row g-0 text-center bg-white bb py-3 min1500">
            <div className="col lines">{props.fechaCorte}</div>
            <div className="col lines">{props.hora}</div>
            <div className="col lines">{props.agente}</div>
            <div className="col lines">{props.metodo}</div>
            <div className="col lines">{props.montoBTC}</div>
            <div className="col lines">{props.monto_dolar}</div>
            <div className="col lines">{props.marca}</div>
            <div className="col lines">{props.estado}</div>
            <div className="col lines">{props.usuario}</div>
            <div className="col lines">{props.comentario}</div>
        </div>
    );
};
ListCobros.propTypes = {
    cobroID: PropTypes.number,
    fechaCorte: PropTypes.string,
    hora: PropTypes.string,
    agente: PropTypes.string,
    metodo: PropTypes.string,
    montoBTC: PropTypes.string,
    monto_dolar: PropTypes.string,
    marca: PropTypes.string,
    estado: PropTypes.string,
    comentario: PropTypes.string,
    usuario: PropTypes.string,
};
export default ListCobros;
