import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListMarketingReporte = props => {

    return (
        <div className="row g-0 text-center bg-white bb py-3 min1500">
            <div className="co1 lines">{props.usuario}</div>
            <div className="col lines">{props.fecha}</div>
            <div className="col lines">{props.marca}</div>
            <div className="col lines">{props.reporte}</div>
            <div className="col lines"><Link to={`/edit-marketing-report/${props.id}`}>Edit</Link></div>
        </div>
    );
};
ListMarketingReporte.propTypes = {
    reporteID: PropTypes.number,
    fecha: PropTypes.string,
    usuario: PropTypes.string,
    marca: PropTypes.string,
    reporte: PropTypes.string,
};
export default ListMarketingReporte;
