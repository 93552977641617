import { useParams } from 'react-router-dom';
import React, { useState, useContext } from "react";
import { Context } from "../../store/appContext";
import { useNavigate } from 'react-router-dom';
import { Row } from "reactstrap";

export default function EditCobroAffiliate() {
    document.title = "Company | Edit Collection Info";
    const navigate = useNavigate();
    const params = useParams();
    const { store } = useContext(Context);

    const marcasPPH = []
    store.marcas.forEach((i) => {
        if (i.comentario === "PPH") {
            marcasPPH.push(i.marca); // Almacena la marca si cumple con la condición
        }
    });

    const actualizar = () => {
        window.location.reload(true);
    }
    const agentesPPH = [];
    store.agentes.forEach((i) => {
      agentesPPH.push(i.agencia); // Almacena el nombre si cumple con la condición
    }); 
    let userLog =   localStorage.getItem('Name');

    const [fechaCorte, setFechaCorte] = useState(store.cobroAffiliate[params.theid] && store.cobroAffiliate[params.theid].fechaCorte);
    const [hora, setHora] = useState(store.cobroAffiliate[params.theid] && store.cobroAffiliate[params.theid].hora);
    const [agente, setAgente] = useState(store.cobroAffiliate[params.theid] && store.cobroAffiliate[params.theid].agente);
    const [metodo, setMetodo] = useState(store.cobroAffiliate[params.theid] && store.cobroAffiliate[params.theid].metodo);
    const [montoBTC, setMontoBTC] = useState(store.cobroAffiliate[params.theid] && store.cobroAffiliate[params.theid].montoBTC);
    const [monto_dolar, setMonto_dolar] = useState(store.cobroAffiliate[params.theid] && store.cobroAffiliate[params.theid].monto_dolar);
    const [marca, setMarca] = useState(store.cobroAffiliate[params.theid] && store.cobroAffiliate[params.theid].marca);
    const [estado, setEstado] = useState(store.cobroAffiliate[params.theid] && store.cobroAffiliate[params.theid].estado);
    const [comentario, setComentario] = useState(store.cobroAffiliate[params.theid] && store.cobroAffiliate[params.theid].comentario);

    console.log(comentario );
    const crear = e => {
        e.preventDefault();
        const body = {
            fechaCorte: fechaCorte,
            hora: hora,
            agente: agente,
            montoBTC: montoBTC,
            metodo: metodo,
            monto_dolar: monto_dolar,
            marca: marca,
            estado: estado,
            comentario: comentario,
            usuario: userLog
        };
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/cobrosAfilate/" + store.cobroAffiliate[params.theid].cobroID, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                alert("Collection was updated");
                navigate('/affiliate-collections');
                actualizar();
            })
            .catch(err => {console.log(err);console.log(body)});
    };
    const metodoPadgo = ["Crypto", "Deposit"];
    const estadoPago = ["Pending", "Confirmed"];
    return (
        <React.Fragment>
            <div className="page-content">
            </div>
            <form onSubmit={crear} id="miFormNoticia">
                    <div className="card p-1">
                        <Row className="g-0">
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Date</div>
                                <input className="form-control selectInner shadow" type="date" placeholder="Date" aria-label="Date" defaultValue={store.cobroAffiliate[params.theid] && store.cobroAffiliate[params.theid].fechaCorte} onChange={e => setFechaCorte(e.target.value)} />
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Hour</div>
                                <input className="form-control selectInner shadow" type="time" placeholder="Hour" aria-label="Hour" defaultValue={store.cobroAffiliate[params.theid] && store.cobroAffiliate[params.theid].hora} onChange={e => setHora(e.target.value)} />
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Agency</div>
                                <select className="form-select" name="Agent" aria-label="Agent" defaultValue={store.cobroAffiliate[params.theid] && store.cobroAffiliate[params.theid].agente} onChange={e => setAgente(e.target.value)} >
                                    {
                                        agentesPPH.map((index) => {
                                            return (
                                                <option key={index} name="ano" value={index} >{index}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Payment method</div>
                                <select className="form-select" name="Payment method" aria-label="Payment method" defaultValue={store.cobroAffiliate[params.theid] && store.cobroAffiliate[params.theid].metodo} onChange={e => setMetodo(e.target.value)} >
                                    {
                                        metodoPadgo.map((index) => {
                                            return (
                                                <option key={index} name="ano" value={index} >{index}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Amount in Crypto</div>
                                <input className="form-control selectInner shadow" type="number" placeholder="Amount in Crypto" aria-label="Amount in Crypto"
                                defaultValue={store.cobroAffiliate[params.theid] && store.cobroAffiliate[params.theid].montoBTC} onChange={e => setMontoBTC(e.target.value)} />
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Amount in Deposit</div>
                                <input className="form-control selectInner shadow" type="number" placeholder="Amount in Deposit" aria-label="Amount in Deposit" defaultValue={store.cobroAffiliate[params.theid] && store.cobroAffiliate[params.theid].monto_dolar} onChange={e => setMonto_dolar(e.target.value)} />
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Brand</div>
                                <select className="form-select" name="Brand" aria-label="Brand"
                                defaultValue={store.cobroAffiliate[params.theid] && store.cobroAffiliate[params.theid].marca} onChange={e => setMarca(e.target.value)} >
                                    {
                                        marcasPPH.map((index) => {
                                            return (
                                                <option key={index} name="ano" value={index} >{index}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Payment Status</div>
                                <select className="form-select" name="Payment Status" aria-label="Payment Status" onChange={e => setEstado(e.target.value)} defaultValue={store.cobroAffiliate[params.theid] && store.cobroAffiliate[params.theid].estado}>
                                    {
                                        estadoPago.map((index) => {
                                            return (
                                                <option key={index} name="ano" value={index} >{index}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Comment</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Comment" aria-label="Comment" defaultValue={store.cobroAffiliate[params.theid] && store.cobroAffiliate[params.theid].comentario}  onChange={e => setComentario(e.target.value)} />
                            </div>
                        </Row>
                        <div className="col-12 py-3">
                            <button type="submit" className="btn btn-danger">Edit</button>
                        </div>
                    </div>
                </form>
        </React.Fragment>
    );
};