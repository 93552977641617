import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListAgents = props => {

    return (
        <Link to={`/edit-agent/${props.id}`}>
            <div className="row g-0 text-center bg-white bb py-3 min1500">
                <div className="col lines">{props.nombre}</div>
                <div className="col lines">{props.estado}</div>
                <div className="col lines">{props.telefono}</div>
                <div className="col lines">{props.extension_asignada}</div>
                <div className="col lines">{props.email}</div>
                <div className="col lines">{props.agencia}</div>
                <div className="col lines">{props.PPH_asignado}</div>
                <div className="col lines">{props.jugadores}</div>
                <div className="col lines">{props.precioPPH}</div>
                <div className="col lines">{props.precioLiveBetting}</div>
                <div className="col lines">{props.precioLivePropBuilder}</div>
                <div className="col lines">{props.porcentajeLiveDealer}</div>
                <div className="col lines text-nowrap overfolw-hidden">{props.comentario}</div>
            </div>
        </Link>
    );
};
ListAgents.propTypes = {
    agenteID: PropTypes.number,
    nombre: PropTypes.string,
    estado: PropTypes.string,
    telefono: PropTypes.string,
    email: PropTypes.string,
    agencia: PropTypes.string,
    PPH_asignado: PropTypes.string,
    precioPPH: PropTypes.string,
    precioLiveBetting: PropTypes.string,
    precioLivePropBuilder: PropTypes.string,
    porcentajeLiveDealer: PropTypes.string,
    jugadores: PropTypes.string,
    extension_asignada: PropTypes.string,
    comentario: PropTypes.string,
};
export default ListAgents;
