import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";


const ListMarca = props => {

 return (
        <div className="row g-0 text-center bg-white bb py-3 min1500">
            <div className="col lines">{props.marca}</div>
            <div className="col lines">{props.plataforma}</div>
            <div className="col lines">{props.comentario}</div>
            <div className="col lines fs-2">
                <a href={`${props.facebook}`} target="_blank">
                    <i className="bx bxl-facebook-circle"></i>
                </a>
            </div>
            <div className="col lines fs-2">
                <a href={`${props.instagram}`} target="_blank">
                    <i className="bx bxl-instagram"></i>
                </a>
            </div>
            <div className="col lines fs-2">
                <a href={`${props.twitter}`} target="_blank">
                    <i className="fab fa-twitter-square"></i>
                </a>
            </div>
            <div className="col lines fs-2">
                <a href={`${props.youtube}`} target="_blank">
                    <i className="fab fa-youtube"></i>
                </a>
            </div>
            <div className="col lines fs-2">
                <a href={`${props.landing_pages}`} target="_blank">
                    <i className="mdi mdi-web"></i>
                </a>
            </div>
            <div className="col lines">{props.usuarios}</div>
        </div>
    );
};
ListMarca.propTypes = {
    agenteID: PropTypes.number,
    plataforma: PropTypes.string,
    marca: PropTypes.string,
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    twitter: PropTypes.string,
    landing_pages: PropTypes.string,
    youtube: PropTypes.string,
    usuarios: PropTypes.string,
    comentario: PropTypes.string,
};
export default ListMarca;
