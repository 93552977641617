import React, { useState, useEffect } from "react";
import getState from "./flux.js";
export const Context = React.createContext(null);
const injectContext = PassedComponent => {
	const StoreWrapper = props => {
		//this will be passed as the contenxt value
		const [state, setState] = useState(
			getState({
				getStore: () => state.store,
				getActions: () => state.actions,
				setStore: updatedStore =>
					setState({
						store: Object.assign(state.store, updatedStore),
						actions: { ...state.actions }
					})
			})
		);

		useEffect(() => {
             state.actions.loadEmpresa();
             state.actions.loadActivos();
             state.actions.loadMarcas();
             state.actions.loadAgentes();
             state.actions.loadClientesPercentage();
             state.actions.loadAfiliados();
             state.actions.loadWLCustomer();
             state.actions.loadReviewCustomer();
             state.actions.loadCustomerFollow();
             state.actions.loadCobro();
             state.actions.loadCobroWL();
             state.actions.loadCobroPercentage();
             state.actions.loadCobroReview();
             state.actions.loadCobroAffiliate();
             state.actions.loadBalanceJazz();
             state.actions.loadBalanceBuckeye();
             state.actions.loadBalanceHorizon();
             state.actions.loadBalancePPHCR();
             state.actions.loadDepositos();
             state.actions.loadDepositosCasino();
             state.actions.loadRetiros();
             state.actions.loadRetirosCasino();
             state.actions.loadJugadores();
             state.actions.loadJugadoresCasino();
             state.actions.loadSuministros();
             state.actions.loadSoftware();
             state.actions.loadReportesPPH();
             state.actions.loadReportesWL();
             state.actions.loadReportesPercentage();
             state.actions.loadReportesReview();
             state.actions.loadReportesAffiliate();
             state.actions.loadMarketingReportes();
             state.actions.loadColaborador();
             state.actions.loadUsers();
             state.actions.loadTemplates();
			 state.actions.loadIngresos();
			 state.actions.changename();
			 state.actions.loadTiquetes();
			 state.actions.loadFreelance();
		}, []);

		return (
			<Context.Provider value={state}>
				<PassedComponent {...props} />
			</Context.Provider>
		);
	};
	return StoreWrapper;
};

export default injectContext;
