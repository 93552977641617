import React, { useContext } from "react";
import ListJugador from "./listJugador";
import { Context } from "../../store/appContext";
import { Row } from "reactstrap";

export default function RecordJugadorCasino() {
    const { store } = useContext(Context);

    const jugadoresCasino = []
    store.marcas.forEach((i) => {
        if (i.comentario === "Casino") {
            jugadoresCasino.push(i.marca); // Almacena la marca si cumple con la condición
        }
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="g-0 overflow-scroll">
                    <Row className="row g-0 text-center bg-base text-white py-3 min1500">
                        <div className="col lines">Name</div>
                        <div className="col lines">Phone</div>
                        <div className="col lines">Email</div>
                        <div className="col lines">Casino</div>
                        <div className="col lines">Fav Gane</div>
                        <div className="col lines">Deposit</div>
                        <div className="col lines">Withdrawals</div>
                        <div className="col lines">Comment</div>
                    </Row>


                    {
                        store.jugadoresCasino.map((item, index) => {
                            return (
                                <div key={index} className="col-12">
                                    <ListJugador
                                        id={index}
                                        jugadorID={item.jugadorID}
                                        nombre={item.nombre}
                                        telefono={item.telefono}
                                        email={item.email}
                                        marca={item.marca}
                                        juego_preferido={item.juego_preferido}
                                        deposito={item.deposito}
                                        retiros={item.retiros}
                                        comentario={item.comentario}
                                    />
                                </div>
                            );
                        })}
                </Row>
            </div>
        </React.Fragment >
    )
}
