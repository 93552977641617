import React, { useContext, useState, useEffect } from "react";
import ListBalanceJazz from './listBalanceJazz';

import { Context } from "../../store/appContext";
import { Row } from "reactstrap";

export default function RecordBalanceJazz() {
    const { store } = useContext(Context);
    let date = new Date();
    let year = date.getFullYear();
    let mesActual = date.toLocaleString('en-US', { month: 'short' });

    let days = [];
    for (let i = 1; i < 32; i++) {
        if (i < 10) {
            i = "0" + i
        }
        days.push(i)
    };

    const [mes, setMes] = useState(mesActual);
    const [ano, setAno] = useState(year);
    const [dia1, setDia1] = useState(days[0]);
    const [dia2, setDia2] = useState(days[0]);
    //mapeo de cantidad de jugadores
    let pphTotalPlayers = []
    let lbTotalPlayers = [];
    let pbTotalPlayers = [];
    console.log(pphTotalPlayers)
    // mapeo de totales 

    let servicesGlobalTotal = [];
    let pphGlobalTotal = [];
    let lbGlobalTotal = [];
    let pbGlobalTotal = [];
    let ldGlobalTotal = [];
    let proCasinoGlobalTotal = [];

    store.balanceJazz.map((item) => {
        if (item.fecha.slice(6, 9) == mes && item.fecha.slice(10, 14) == ano) {
            pphTotalPlayers.push(parseInt(item.pphPlayers));
            lbTotalPlayers.push(parseInt(item.lbPlayers));
            pbTotalPlayers.push(parseInt(item.pbPlayers));

            servicesGlobalTotal.push(parseInt(item.totalSpLbPb));
            pphGlobalTotal.push(parseInt(item.pphTotal));
            lbGlobalTotal.push(parseInt(item.lbTotal));
            pbGlobalTotal.push(parseInt(item.pbTotal));
            ldGlobalTotal.push(parseInt(item.liveDealerTotal));
            proCasinoGlobalTotal.push(parseInt(item.proCasinoLoss));
        }
    });
    //fincion suma
    const calculateTotal = (array) => array.reduce((total, num) => total + num, 0);

    //suma de jugadores
    const pphCantidadTotalPlayers = calculateTotal(pphTotalPlayers);
    const lbCantidadTotalPlayers = calculateTotal(lbTotalPlayers);
    const pbCantidadTotalPlayers = calculateTotal(pbTotalPlayers);

    // suma de totales

    const pphTotal = calculateTotal(pphGlobalTotal);
    const lbTotal = calculateTotal(lbGlobalTotal);
    const pbTotal = calculateTotal(pbGlobalTotal);
    const servicesTotal = calculateTotal(servicesGlobalTotal);
    const ldTotal = calculateTotal(ldGlobalTotal);
    const proCasinoTotal = calculateTotal(proCasinoGlobalTotal);

    ///let proCasinoTotal = proCasinoGlobalTotal.reduce((total, num) => total + num, 0);
    //suma gran total
    let granTotal = servicesTotal + proCasinoTotal + ldTotal;

    const meses = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    return (
        <React.Fragment>
            <div className="page-content">
                <h2>Balance Jazz</h2>
                <Row className="g-0 py-1">
                    <div className="col-lg-1">
                        <p>Day 1</p>
                        <select className="form-select" name="mes" aria-label="mes" onChange={e => setDia1(e.target.value)} >
                            {
                                days.map((index) => {
                                    return (
                                        <option key={index} name="ano" value={index} >{index}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="col-lg-1">
                        <p>Day 2</p>
                        <select className="form-select" name="mes" aria-label="mes" onChange={e => setDia2(e.target.value)} >
                            {
                                days.map((index) => {
                                    return (
                                        <option key={index} name="ano" value={index} >{index}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="col-lg-2">
                        <p>Month</p>
                        <select className="form-select" name="mes" aria-label="mes" defaultValue={mesActual} onChange={e => setMes(e.target.value)} >
                            {
                                meses.map((index) => {
                                    return (
                                        <option key={index} name="ano" value={index} >{index}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="col-lg-2">
                        <p>Year</p>
                        <input min={2023} max={2050} className="form-control selectInner shadow" type="number" placeholder={year} onChange={e => setAno(e.target.value)}></input>
                    </div>
                </Row>
                <Row className="g-0 overflow-scroll">
                    <Row className="row g-0 text-center bg-base text-white py-3 min1500">
                        <div className="col lines">User</div>
                        <div className="col lines">Agency</div>
                        <div className="col lines">Date</div>
                        <div className="col lines">PPH Players</div>
                        <div className="col lines">PPH Price</div>
                        <div className="col lines text-danger">PPH Total</div>
                        <div className="col lines">LB Players</div>
                        <div className="col lines">LB Price</div>
                        <div className="col lines text-danger">LB Total</div>
                        <div className="col lines">PB Players</div>
                        <div className="col lines">PB Price</div>
                        <div className="col lines text-danger">PB Total</div>
                        <div className="col lines text-danger">Serv Total</div>
                        <div className="col lines">LD Lost</div>
                        <div className="col lines">LD %</div>
                        <div className="col lines text-danger">LD Total</div>
                        <div className="col lines">Pro Casino</div>
                        <div className="col lines text-danger">Total</div>
                    </Row>

                    {
                        // Obtener una copia de balanceBuckeye filtrada y ordenada alfabéticamente
                        [...store.balanceJazz]
                            // Filtrar los elementos por fecha
                            .filter(item => (
                                item.fecha.slice(0, 2) == dia1 &&
                                item.fecha.slice(3, 5) == dia2 &&
                                item.fecha.slice(6, 9) == mes &&
                                item.fecha.slice(10, 14) == ano
                            ))
                            // Ordenar alfabéticamente por el nombre del agente
                            .sort((a, b) => {
                                const agenteA = a.agente.toUpperCase();
                                const agenteB = b.agente.toUpperCase();
                                // Función de comparación que coloca los nombres que comienzan con un número al final
                                if (!isNaN(agenteA[0]) && isNaN(agenteB[0])) {
                                    return 1; // a comienza con un número, debe ir después de b
                                } else if (isNaN(agenteA[0]) && !isNaN(agenteB[0])) {
                                    return -1; // b comienza con un número, a debe ir antes de b
                                } else {
                                    return agenteA.localeCompare(agenteB); // Ambos son cadenas de texto, ordenar normalmente
                                }
                            })
                            .map((item, index) => (
                                <div key={index} className="col-12">
                                    <ListBalanceJazz
                                        id={index}
                                        cobroID={item.cobroID}
                                        usuario={item.usuario}
                                        agente={item.agente}
                                        fecha={item.fecha.split("#")[0]}
                                        pphPrice={item.pphPrice}
                                        pphPlayers={item.pphPlayers}
                                        pphTotal={item.pphTotal}
                                        lbPlayers={item.lbPlayers}
                                        lbPrice={item.lbPrice}
                                        lbTotal={item.lbTotal}
                                        pbPlayers={item.pbPlayers}
                                        pbPrice={item.pbPrice}
                                        pbTotal={item.pbTotal}
                                        totalSpLbPb={item.totalSpLbPb}
                                        liveDealerLoss={item.liveDealerLoss}
                                        liveDealerPorcentaje={item.liveDealerPorcentaje}
                                        liveDealerTotal={item.liveDealerTotal}
                                        proCasinoLoss={item.proCasinoLoss}
                                        total={item.total}
                                    />
                                </div>
                            ))
                    }

                    <Row className="row g-0 text-center bg-base text-white min1500">
                        <div className="col lines py-3"></div>
                        <div className="col lines py-3"></div>
                        <div className="col lines py-3"></div>
                        <div className="col lines py-3">{pphCantidadTotalPlayers}</div>
                        <div className="col lines py-3"></div>
                        <div className="col lines py-3 text-danger">{pphTotal}</div>
                        <div className="col lines py-3">{lbCantidadTotalPlayers}</div>
                        <div className="col lines py-3"></div>
                        <div className="col lines py-3 text-danger">{lbTotal}</div>
                        <div className="col lines py-3">{pbCantidadTotalPlayers}</div>
                        <div className="col lines py-3"></div>
                        <div className="col lines py-3 text-danger">{pbTotal}</div>
                        <div className="col lines py-3 text-danger">{servicesTotal}</div>
                        <div className="col lines py-3"></div>
                        <div className="col lines py-3"></div>
                        <div className="col lines py-3 text-danger">{ldTotal}</div>
                        <div className="col lines py-3 text-danger">{proCasinoTotal}</div>
                        <div className="col py-3 fw-bold fs-5 text-white bg-success">${granTotal}</div>
                    </Row>
                </Row>
            </div>
        </React.Fragment >
    )
}
