import React, { useContext } from "react";
import ListReviewCustomer from "./listReviewCustomer";
import { Context } from "../../store/appContext";
import { Row } from "reactstrap";

export default function RecordReviewCustomer() {
    const { store } = useContext(Context);
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="g-0 overflow-scroll">
                    <Row className="row g-0 text-center bg-base text-white py-3 min1500">
                        <div className="col lines">Name</div>
                        <div className="col lines">Phone</div>
                        <div className="col lines">Email</div>
                        <div className="col lines">Brand</div>
                        <div className="col lines">Comment</div>
                    </Row>

                    {
                        store.reviewCustomer.map((item, index) => {
                            return (
                                <div key={index} className="col-12">
                                    <ListReviewCustomer
                                        id={index}
                                        afiliadoID={item.afiliadoID}
                                        nombre={item.nombre}
                                        telefono={item.telefono}
                                        email={item.email}
                                        marca={item.marca}
                                        comentario={item.comentario}
                                    />
                                </div>
                            );
                        })}
                </Row>
            </div>
        </React.Fragment >
    )
}
