import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

//import images
import logoSm from "../../assets/images/logo-sm.png";
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType
} from "../../store/actions";

const Header = (props) => {

  const userName = localStorage.getItem("Name");

  // --------------registro de logout
  const ahora = new Date();
  //se obtiene fecha de marca
  const ano = ahora.getFullYear(); // Obtiene el año 
  const mes = ahora.getMonth() + 1; // Obtiene el mes
  const dia = ahora.getDate(); // Obtiene el dia

  //se obtine hora para marca
  const horaActual = ahora.getHours(); // Obtiene la hora actual (formato 24 horas)
  const minutoActual = ahora.getMinutes(); // Obtiene el minuto actual
  const marcas = horaActual + ':' + minutoActual;
  const formatearNumero = (numero) => {
    return numero < 10 ? `0${numero}` : numero;
  };

  const fecha = ano + "/" + formatearNumero(mes) + "/" + formatearNumero(dia);
  const marca = async () => {
    try {
      const response = await fetch('https://crmsbapp-7f82716e70c9.herokuapp.com/ingreso', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          marca: marcas,
          fecha: fecha,
          nombre: userName
        }),
      });

    } catch (error) {
      console.error('Error:', error.message);
      // Manejo de errores, como mostrar un mensaje de error al usuario
    }
    handleClick()
  };

  // --------------registro de logout


  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }


  const handleClick = () => {
    // Borra el elemento del localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('puesto');
    localStorage.removeItem('Name');
    // Recarga la página
    window.location.reload();
  };


  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    toggleSidebar()
  }, [])


  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Cambia el estado de isSidebarOpen
    const sidebar = document.getElementById("sidebar"); // Cambia el ID según tu estructura
    if (sidebar) {
      if (isSidebarOpen) {
        sidebar.classList.remove("header-with-0");
        sidebar.classList.add("header-with-100");
      } else {
        sidebar.classList.remove("header-with-100");
        sidebar.classList.add("header-with-0");
      }
    }

  };


  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box text-center">
              <Link to="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img className="img-fluid" src={logoSm} alt="logo-sm-dark" height="22" />
                </span>
                <span className="logo-lg-100">
                  <img className="img-fluid pt-lg-4" src={logoDark} alt="logo-dark" />
                </span>
              </Link>

              <Link to="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img className="img-fluid pt-lg-4" src={logoSm} alt="logo-sm-light" height="22" />
                </span>
                <span className="logo-lg ">
                  <img className="img-fluid pt-lg-4" src={logoLight} alt="logo-light" />
                </span>
              </Link>
            </div>
            <div className="d-flex align-items-center">
              <span className="fw-bold fs-1">{userName}</span>
            </div>
          </div>
          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon"
                data-toggle="fullscreen"
              >
                <i className="ri-fullscreen-line" />
              </button>
            </div>
            <button className="btn btn-primary my-4" onClick={marca}>
              <i className="ri-shut-down-line align-middle me-2" />
              <span>{props.t("Logout")}</span>
            </button>
          </div>
          <div className="d-flex">
            <div className="d-inline-block d-lg-none ms-1">
              <button className="btn" type="button" onClick={toggleSidebar}>
                <i className="fas fa-bars"></i>
              </button>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  toggleLeftmenu
})(withTranslation()(Header));
