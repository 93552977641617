import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Context } from "../../store/appContext";
import { Link } from "react-router-dom";

const ListBalanceHorizon = props => {
    const { store } = useContext(Context);

    const agentesPPH = [];

    store.agentes.forEach((i) => {
        if (i.nombre === props.agente) {
            agentesPPH.push(i.comentario); // Almacena la marca si cumple con la condición
        }
    });

    const commentAlert = () => {
        alert(agentesPPH)
    }

    let total = parseInt(props.totalSpLbPb) + parseInt(props.liveDealerTotal) + parseInt(props.proCasinoLoss);

    return (
        <div className="row g-0 text-center bg-white bb min1500">
            <div className="col lines py-3">
                <Link onClick={commentAlert}>{props.usuario}</Link>
            </div>
            <div className="col lines py-3">
                <Link to={`/edit-balance-horizon/${props.id}`}>{props.agente}</Link>
            </div>
            <div className="col lines py-3">{props.fecha}</div>
            <div className="col lines py-3">{props.pphPlayers}</div>
            <div className="col lines py-3">{props.pphPrice}</div>
            <div className="col lines py-3">{props.pphTotal}</div>
            <div className="col lines py-3">{props.lbPlayers}</div>
            <div className="col lines py-3">{props.lbPrice}</div>
            <div className="col lines py-3">{props.lbTotal}</div>
            <div className="col lines py-3">{props.pbPlayers}</div>
            <div className="col lines py-3">{props.pbPrice}</div>
            <div className="col lines py-3">{props.pbTotal}</div>
            <div className="col lines py-3">{props.totalSpLbPb}</div>
            <div className="col lines py-3">{props.liveDealerLoss}</div>
            <div className="col lines py-3">{props.liveDealerPorcentaje}</div>
            <div className="col lines py-3">{props.liveDealerTotal}</div>
            <div className="col lines py-3">{props.proCasinoLoss}</div>
            <div className="col lines py-3 fw-bold fs-5 text-white bg-success">${total}</div>
        </div>
    );
};
ListBalanceHorizon.propTypes = {
    cobroID: PropTypes.number,
    usuario: PropTypes.string,
    agente: PropTypes.string,
    fecha: PropTypes.string,
    pphPrice: PropTypes.string,
    pphPlayers: PropTypes.string,
    pphTotal: PropTypes.string,
    lbPlayers: PropTypes.string,
    lbPrice: PropTypes.string,
    lbTotal: PropTypes.string,
    pbPlayers: PropTypes.string,
    pbPrice: PropTypes.string,
    pbTotal: PropTypes.string,
    totalSpLbPb: PropTypes.string,
    liveDealerLoss: PropTypes.string,
    liveDealerPorcentaje: PropTypes.string,
    liveDealerTotal: PropTypes.string,
    proCasinoLoss: PropTypes.string,
    total: PropTypes.number,
};
export default ListBalanceHorizon;
