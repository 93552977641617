import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListJugador = props => {

    return (
        <div className="row g-0 text-center bg-white bb py-3 min1500">
            <div className="col lines">{props.nombre}</div>
            <div className="col lines">{props.telefono}</div>
            <div className="col lines">{props.email}</div>
            <div className="col lines">{props.marca}</div>
            <div className="col lines">{props.juego_preferido}</div>
            <div className="col lines">{props.deposito}</div>
            <div className="col lines">{props.retiros}</div>
            <div className="col lines">{props.comentario}</div>
        </div>
    );
};
ListJugador.propTypes = {
    agenteID: PropTypes.number,
    nombre: PropTypes.string,
    telefono: PropTypes.string,
    email: PropTypes.string,
    marca: PropTypes.string,
    juego_preferido: PropTypes.string,
    retiros: PropTypes.string,
    deposito: PropTypes.string,
    comentario: PropTypes.string,
};
export default ListJugador;
