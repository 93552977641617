import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../store/appContext";
import { Row } from "reactstrap";
import ListIngresos from "./listIngresos";

export default function RecordIngresos() {
    const { store } = useContext(Context);

    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth() + 1;
    const anoActual = fechaActual.getFullYear();

    // Función para agregar un cero delante del mes si es menor que 10
    const formatearMes = (mes) => {
        return mes < 10 ? `0${mes}` : mes;
    };

    const [mesIngreso, setMesIngreso] = useState(formatearMes(mesActual));
    const [anoIngreso, setAnoIngreso] = useState(anoActual);

    const empleados = store.colaborador.map((i) => i.nombre); // Obtener solo los nombres de los empleados

    const [empleadoFilter, setEmpleadoFilter] = useState('Roy');

    // Función para ordenar las marcas por fecha y hora
    const ordenarMarcas = (marcas) => {
        return marcas.sort((a, b) => new Date(a.marca) - new Date(b.marca));
    };

    // Función para calcular la diferencia de tiempo entre las marcas
    const calcularDiferenciaTiempo = (marcas) => {
        if (!marcas || marcas.length < 2) return 0;
        const primeraHora = new Date(`01/01/2000 ${marcas[0].marca.split(" - ")[0]}`);
        const segundaHora = new Date(`01/01/2000 ${marcas[1].marca.split(" - ")[0]}`);
        if (isNaN(primeraHora.getTime()) || isNaN(segundaHora.getTime())) return 0;
        const diferenciaMs = segundaHora - primeraHora;
        const horas = Math.floor(diferenciaMs / (1000 * 60 * 60));
        return horas;
    };


    return (
        <React.Fragment>
            <div className="py-2">
                {/* Marcas */}
                <h2>Report of incoming work hours</h2>
                <Row className="py-4">
                    <div className="col-lg-2">
                        <p>Employee</p>
                        <select className="form-select" name="Employee" aria-label="Employee" onChange={e => setEmpleadoFilter(e.target.value)} >
                            {
                                empleados.map((index) => {
                                    return (
                                        <option key={index} name="ano" value={index} >{index}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="col-lg-2">
                        <p>Year</p>
                        <input min={2023} max={2050} className="form-control selectInner shadow" type="number" placeholder={anoIngreso} onChange={e => setAnoIngreso(e.target.value)}></input>
                    </div>
                    <div className="col-lg-2">
                        <p>Month</p>
                        <input min={1} max={12} className="form-control selectInner shadow" type="text" placeholder={mesIngreso} onChange={e => setMesIngreso(e.target.value)}></input>
                    </div>
                </Row>
                <Row className="g-0 overflow-scroll">
                    <Row className="row g-0 text-center bg-base text-white py-3 min1500">
                        <div className="col lines">Name</div>
                        <div className="col lines">Date</div>
                        <div className="col lines">Hour</div>
                        <div className="col lines">Working Hour</div>
                    </Row>
                    {
                        Object.entries(
                            store.ingresos
                                .filter(item => item.fecha.slice(0, 4) == anoIngreso && item.fecha.slice(5, 7) == mesIngreso && item.nombre == empleadoFilter)
                                .reduce((acc, item) => {
                                    const key = `${item.nombre}-${item.fecha}`;
                                    acc[key] = acc[key] || [];
                                    acc[key].push(item);
                                    return acc;
                                }, {})
                        ).map(([key, marcasPorEmpleadoFecha]) => (
                            <div key={key} className="row g-0 text-center min1500">
                                <div className="col">
                                    <p>{marcasPorEmpleadoFecha[0].nombre}</p>
                                </div>
                                <div className="col lines"><p>{marcasPorEmpleadoFecha[0].fecha}</p></div>
                                <div className="col lines"><p>{marcasPorEmpleadoFecha.map(marca => marca.marca.split(" - ")[0]).join(", ")}</p></div>
                                <div className="col lines"><p>{calcularDiferenciaTiempo(marcasPorEmpleadoFecha)} hours</p></div>
                                
                               
                                
                            </div>
                        ))
                    }
                </Row>
            </div>
        </React.Fragment >
    )
}
