import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListEmpresa = props => {

    return (
        <div className="row g-0 text-center min1500" >
            <div className="col">
                <p>{props.nombre}</p>
            </div>
            <div className="col">
                <p>{props.sociedad}</p>
            </div>
            <div className="col">
                <p>{props.cedula_juridica}</p>
            </div>
            <div className="col">
                <p>{props.email_administracion}</p>
            </div>
            <div className="col">
                <p>{props.telefono}</p>
            </div>
        </div>
    );
};
ListEmpresa.propTypes = {
    empresaID: PropTypes.number,
    nombre: PropTypes.string,
    sociedad: PropTypes.string,
    cedula_juridica: PropTypes.string,
    email_administracion: PropTypes.string,
    telefono: PropTypes.string,
};
export default ListEmpresa;
