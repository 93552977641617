import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListTemplate = props => {

    // let roy = window.localStorage.getItem("my_token", JSON.stringify());
    // if (!roy) {
    //     window.location.href = '/';
    // } else {

    // }

    return (
        <div className="row g-0 text-center bg-white bb py-3 min1500">
            <div className="col-3 lines">{props.nombre}</div>
            <div className="col-2 lines">{props.departamento}</div>
            <div className="col-3 lines">{props.uso}</div>
            <div className="col-3 lines">
                <a className="fs-1" target="_blank" href={props.archivo}>
                    <i className="bx bxs-file-pdf"></i>
                </a>

            </div>
            <div className="col-1 lines"><Link to={`/edit-template/${props.id}`}>Edit</Link></div>
        </div>
    );
};
ListTemplate.propTypes = {
    templateID: PropTypes.number,
    nombre: PropTypes.string,
    departamento: PropTypes.string,
    uso: PropTypes.string,
    archivo: PropTypes.string,
};
export default ListTemplate;
