import { useParams } from 'react-router-dom';
import React, { useState, useContext } from "react";
import { Context } from "../../store/appContext";
import { useNavigate } from 'react-router-dom';
import { Row } from "reactstrap";

export default function EditEmpresa() {
    document.title = "Company | Edit Company Info";
    const navigate = useNavigate();
    const params = useParams();
    const { store } = useContext(Context);

    const [nombre, setAutor] = useState(store.empresa[params.theid] && store.empresa[params.theid].nombre);
    const [sociedad, setSociedad] = useState(store.empresa[params.theid] && store.empresa[params.theid].sociedad);
    const [cedula_juridica, setCedula] = useState(store.empresa[params.theid] && store.empresa[params.theid].cedula_juridica);
    const [email_administracion, setEmailAdmin] = useState(store.empresa[params.theid] && store.empresa[params.theid].email_administracion);
    const [comentarios, setComentarios] = useState(store.empresa[params.theid] && store.empresa[params.theid].comentarios);
    const [telefono, setTelefono] = useState(store.empresa[params.theid] && store.empresa[params.theid].telefono);

    const actualizar = () => {
        window.location.reload(true);
    }
    const crear = e => {
        e.preventDefault();
        const body = {
            nombre: nombre,
            cedula_juridica: cedula_juridica,
            comentarios: comentarios,
            email_administracion: email_administracion,
            sociedad: sociedad,
            telefono: telefono
        };
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/empresa/" + store.empresa[params.theid].empresaID, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                
                alert("Company was updated");
                navigate('/company');
                actualizar();
            })
            .catch(err => console.log(err));
    };

    const delet = e => {
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/empresa/" + store.empresa[params.theid].empresaID, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" }
        })
            .then(() => {
                alert("Company was Delet");
                actualizar();
                navigate('/company');
            })
            .catch(err => console.log(err));
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="card p-2">
                    <h1>{nombre}</h1>
                    <form onSubmit={crear} >
                        <Row className="g-0">
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Company</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Company" defaultValue={store.empresa[params.theid] && store.empresa[params.theid].nombre} aria-label="Company" onChange={e => setAutor(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Society </div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Society" defaultValue={store.empresa[params.theid] && store.empresa[params.theid].sociedad} aria-label="Society" onChange={e => setSociedad(e.target.value)} />
                            </div>

                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Legal ID </div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Legal ID" defaultValue={store.empresa[params.theid] && store.empresa[params.theid].cedula_juridica} aria-label="Legal ID" onChange={e => setCedula(e.target.value)} />
                            </div>

                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Email Admin </div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Email Admin" defaultValue={store.empresa[params.theid] && store.empresa[params.theid].email_administracion} aria-label="Email Admin" onChange={e => setEmailAdmin(e.target.value)} />
                            </div>

                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Phone</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Phone" defaultValue={store.empresa[params.theid] && store.empresa[params.theid].telefono} aria-label="Phone" onChange={e => setTelefono(e.target.value)} />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-4 py-3">
                                <button type="submit" className="btn btn-success">Save</button>
                            </div>
                            <div className="col-4 text-start p-3">
                                <button className="btn btn-danger" onClick={delet} data-bs-dismiss="modal">Delete</button>
                            </div>
                        </Row>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
};