import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListColaborador = props => {

    // let roy = window.localStorage.getItem("my_token", JSON.stringify());
    // if (!roy) {
    //     window.location.href = '/';
    // } else {

    // }

    return (
        <div className="row g-0 text-center bg-white bb py-3 min1500">
            <div className="col lines">{props.nombre}</div>
            <div className="col lines">{props.cedula}</div>
            <div className="col lines">{props.telefono}</div>
            <div className="col lines">{props.email}</div>
            <div className="col lines">{props.fechaIngreso}</div>
            <div className="col lines">{props.puesto}</div>
            <div className="col lines">{props.horario}</div>
            <div className="col lines">{props.salario}</div>
            <div className="col lines">{props.vacaciones}</div>
            <div className="col lines">{props.comentarios}</div>
        </div>
    );
};
ListColaborador.propTypes = {
    reporteID: PropTypes.number,
    nombre: PropTypes.string,
    cedula: PropTypes.string,
    email: PropTypes.string,
    fechaIngreso: PropTypes.string,
    puesto: PropTypes.string,
    horario: PropTypes.string,
    salario: PropTypes.string,
    vacaciones: PropTypes.string,
    comentarios: PropTypes.string,
};
export default ListColaborador;
