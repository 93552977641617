import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ListFreelance = props => {


    return (
        <div className="row g-0 text-center bg-white bb py-3 min1500">
            <div className="col lines">{props.nombre}</div>
            <div className="col lines">{props.cedula}</div>
            <div className="col lines">{props.correo}</div>
            <div className="col lines">{props.telefono}</div>
            <div className="col lines">{props.monto}</div>
            <div className="col lines">{props.contrato}</div>
            <div className="col lines">{props.tipoContrato}</div>
        </div>
    );
};
ListFreelance.propTypes = {
    freelanceID: PropTypes.number,
    nombre: PropTypes.string,
    cedula: PropTypes.string,
    correo: PropTypes.string,
    telefono: PropTypes.string,
    monto: PropTypes.string,
    contrato: PropTypes.string,
    tipoContrato: PropTypes.string,
};
export default ListFreelance;
