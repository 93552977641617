import React, { useState, useContext } from "react";

import { Context } from "../../store/appContext";
import { Row } from "reactstrap";
export const CreateCustomer = () => {
    const { store } = useContext(Context);

    const marcasSB = []
    store.marcas.forEach((i) => {
        marcasSB.push(i.marca); // Almacena la marca si cumple con la condición
    });

    //previene que marca salga null 
    const defaultMarca = marcasSB.length > 0 ? marcasSB[0] : "";

    const [nombre, setNombre] = useState("");
    const [telefono, setTelefono] = useState("");
    const [email, setEmail] = useState("Roy");
    const [marca, setMarca] = useState(defaultMarca);
    const [comentario, setComentario] = useState("");

    const actualizar = () => {
        window.location.reload(true);
    }

    const crearCustomer = e => {
        e.preventDefault();
        const body = {
            nombre: nombre,
            telefono: telefono,
            email: email,
            marca: marca,
            comentario: comentario
        };

        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/customerFollowUp/", {
            method: "POST",
            body: JSON.stringify(body),
            headers: { "Content-Type": "application/json" }
        })
            .then(res => res.json())
            .then(data => {
                alert("Customer was Created");
                actualizar();
            })
            .catch(err => { console.log(err); console.log(body) });
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="col-12">
                    <h1>Create Custumer Follow Up</h1>
                </div>
                <form onSubmit={crearCustomer} id="miFormNoticia">
                    <div className="card p-1 overflow-scroll">
                        <Row className="g-0 min1500">
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Name</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Name" aria-label="Name" onChange={e => setNombre(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Phone</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Phone" aria-label="Phone" onChange={e => setTelefono(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Email</div>
                                <input className="form-control selectInner shadow" type="mail" placeholder="Email" aria-label="Email" onChange={e => setEmail(e.target.value)} />
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Brand</div>
                                <select className="form-select" name="PPH" aria-label="PPH" onChange={e => setMarca(e.target.value)} >
                                    {
                                        marcasSB.map((index) => {
                                            return (
                                                <option key={index} name="ano" value={index} >{index}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Comment</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Comment" aria-label="Comment" onChange={e => setComentario(e.target.value)} />
                            </div>
                        </Row>
                        <div className="col-12 py-3">
                            <button type="submit" className="btn btn-danger">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}