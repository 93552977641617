import React, { useContext } from "react";
import ListAcitivo from "./listActivo";
import { Context } from "../../store/appContext";
import { Row } from "reactstrap";

export default function RecordActivo() {
    const { store } = useContext(Context);
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="g-0 overflow-scroll">
                    <Row className="row g-0 text-center bg-base text-white py-3 min1500">
                        <div className="col-3 lines">Item</div>
                        <div className="col-3 lines">Model</div>
                        <div className="col-5 lines">Comment</div>
                        <div className="col-1 lines">Edit</div>
                    </Row>

                    {
                        store.activos.map((item, index) => {
                            return (
                                <div key={index} className="col-12">
                                    <ListAcitivo
                                        id={index}
                                        activoID={item.activoID}
                                        item={item.item}
                                        modelo={item.modelo}
                                        comentario={item.comentario}
                                    />
                                </div>
                            );
                        })}
                </Row>
            </div>
        </React.Fragment >
    )
}
