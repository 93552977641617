import { useParams, Redirect } from 'react-router-dom';
import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../store/appContext";
import { useNavigate } from 'react-router-dom';
import { Row } from "reactstrap";

export default function EditRetiroCasino() {
    document.title = "Retiro | Edit Retiro Info";
    const navigate = useNavigate();
    const params = useParams();
    const { store } = useContext(Context);

    const [fecha, setFecha] = useState(store.retirosCasino[params.theid] && store.retirosCasino[params.theid].fecha);
    const [hora, setHora] = useState(store.retirosCasino[params.theid] && store.retirosCasino[params.theid].hora);
    const [jugador, setJugador] = useState(store.retirosCasino[params.theid] && store.retirosCasino[params.theid].jugador);
    const [metodo, setMetodo] = useState(store.retirosCasino[params.theid] && store.retirosCasino[params.theid].metodo);

    const [cantidad, setCantidad] = useState(store.retirosCasino[params.theid] && store.retirosCasino[params.theid].cantidad);

    const [comentario, setComentario] = useState(store.retirosCasino[params.theid] && store.retirosCasino[params.theid].comentario);

    const crear = e => {
        e.preventDefault();
        const body = {
            fecha: fecha,
            hora: hora,
            jugador: jugador,
            cantidad: cantidad,
            metodo: metodo,
            comentario: comentario
        };
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/retiros_casino/" + store.retirosCasino[params.theid].retiroID, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                alert("Withdrawal was updated");
                navigate('/withdrawals-casino'); // Cambia '/ruta_destino' por la ruta a la que deseas redirigir
                actualizar();
            })
            .catch(err => console.log(err));
    };

    const delet = e => {
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/retiros_casino/" + store.retirosCasino[params.theid].retiroID, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" }
        })
            .then(() => {
                alert("The Withdrawal was deleted");
                navigate('/withdrawals-casino'); // Cambia '/ruta_destino' por la ruta a la que deseas redirigir
                actualizar();
            })
            .catch(err => console.log(err));
    };
    let actualizar = () => {
            window.location.reload(true);
    }
    const metodoPadgo = ["Crypto", "Deposit"];
    return (
        <React.Fragment>
            <div className="page-content">
            </div>
            <form onSubmit={crear} id="miFormNoticia">
                <div className="card p-1">
                    <Row className="g-0">
                        <div className="col-1 p-1">
                            <div className="col-12 fw-bold">Date</div>
                            <input className="form-control selectInner shadow" type="date" placeholder="Date" aria-label="Date" defaultValue={store.retirosCasino[params.theid] && store.retirosCasino[params.theid].fecha} onChange={e => setFecha(e.target.value)} />
                        </div>
                        <div className="col-1 p-1">
                            <div className="col-12 fw-bold">Hour</div>
                            <input className="form-control selectInner shadow" type="time" placeholder="Hour" aria-label="Hour" defaultValue={store.retirosCasino[params.theid] && store.retirosCasino[params.theid].hora} onChange={e => setHora(e.target.value)} />
                        </div>
                        <div className="col-1 p-1">
                            <div className="col-12 fw-bold">Player</div>
                            <input className="form-control selectInner shadow" type="text" placeholder="Player" aria-label="Player" defaultValue={store.retirosCasino[params.theid] && store.retirosCasino[params.theid].jugador} onChange={e => setJugador(e.target.value)} />
                        </div>
                        <div className="col-1 p-1">
                            <div className="col-12 fw-bold">Payment method</div>
                            <select className="form-select" name="Payment method" aria-label="Payment method" defaultValue={store.retirosCasino[params.theid] && store.retirosCasino[params.theid].metodo} onChange={e => setMetodo(e.target.value)} >
                                {
                                    metodoPadgo.map((index) => {
                                        return (
                                            <option key={index} name="ano" value={index} >{index}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-3 p-1">
                            <div className="col-12 fw-bold">Quantity</div>
                            <input className="form-control selectInner shadow" type="number" placeholder="Quantity" aria-label="Quantity" defaultValue={store.retirosCasino[params.theid] && store.retirosCasino[params.theid].cantidad} onChange={e => setCantidad(e.target.value)} />
                        </div>
                        <div className="col-3 p-1">
                            <div className="col-12 fw-bold">Brand</div>
                            <input className="form-control selectInner shadow" type="text" placeholder="Comment" aria-label="Comment" defaultValue={store.retirosCasino[params.theid] && store.retirosCasino[params.theid].comentario} onChange={e => setComentario(e.target.value)} />
                        </div>
                    </Row>
                    <Row>

                        <div className="col-4 py-3">
                            <button type="submit" className="btn btn-success">Edit</button>
                        </div>
                        <div className="col-4 text-start p-3">
                            <button className="btn btn-danger" onClick={delet}>Delete</button>
                        </div>
                    </Row>
                </div>
            </form>
        </React.Fragment>
    );
};