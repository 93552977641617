import React, { useContext, useState } from "react";
import { Context } from "../../store/appContext";
import ListTiquete from "../Tiquetes/listTiquete";
import { Row } from "reactstrap";
const Tiquete = () => {
    const { store } = useContext(Context);

    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth() + 1;
    const anoActual = fechaActual.getFullYear();

    const empleados = [];
    store.colaborador.forEach((i) => {
        empleados.push(i.nombre); // Almacena la Usuarios
    });
    const formatearMes = (mes) => {
        return mes < 10 ? `0${mes}` : mes;
    };

    const [mesReportCs, setMesReportCs] = useState(formatearMes(mesActual));
    const [anoReportCs, setAnoReportCs] = useState(anoActual);

    const [empleadoFilter, setEmpleadoFilter] = useState('Roy');

    return (
        <React.Fragment>
            <div className="page-content">
                <h2>Tickets</h2>
                <Row className="py-4">
                    <div className="col-lg-2">
                        <p>Employee</p>
                        <select className="form-select" name="Employee" aria-label="Employee" onChange={e => setEmpleadoFilter(e.target.value)} >
                            {
                                empleados.map((index) => {
                                    return (
                                        <option key={index} name="ano" value={index} >{index}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="col-lg-2">
                        <p>Year</p>
                        <input
                            min={2023}
                            max={2050}
                            className="form-control selectInner shadow"
                            type="number"
                            placeholder={anoReportCs}
                            onChange={(e) => setAnoReportCs(e.target.value)}
                        ></input>
                    </div>
                    <div className="col-lg-2">
                        <p>Month</p>
                        <input
                            min={1}
                            max={12}
                            className="form-control selectInner shadow"
                            type="text"
                            placeholder={mesReportCs}
                            onChange={(e) => setMesReportCs(e.target.value)}
                        ></input>
                    </div>
                </Row>
                <div className="card overflow-scroll">
                    <Row className="row g-0 text-center bg-base text-white min1500">
                        <div className="col">
                            <p><strong>Sender: </strong></p>
                        </div>
                        <div className="col">
                            <p><strong>Recipient: </strong></p>
                        </div>
                        <div className="col">
                            <p><strong>Content: </strong></p>
                        </div>
                        <div className="col">
                            <p><strong>Date: </strong></p>
                        </div>
                        <div className="col">
                            <p><strong>State: </strong></p>
                        </div>
                        <div className="col">
                            <p><strong>Edit: </strong></p>
                        </div>
                    </Row>
                    {store.tiquetes.map((item, index) => {
                        if (item.recipient == empleadoFilter && item.fecha.slice(0, 4) == anoReportCs && item.fecha.slice(5, 7) == mesReportCs) {
                            return (
                                <div key={index}>
                                    <ListTiquete
                                        id={index}
                                        tiqueteID={item.tiqueteID}
                                        sender={item.sender}
                                        recipient={item.recipient}
                                        content={item.content}
                                        fecha={item.fecha}
                                        estado={item.estado}
                                    />
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Tiquete;
