import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../store/appContext";
import { useNavigate } from 'react-router-dom';
import { Row } from "reactstrap";

export const CreateTiquete = () => {
    document.title = "Company | Edit Company Info";
    const navigate = useNavigate();

    const fechaActual = new Date();
    const dia = fechaActual.getDate();
    const mes = fechaActual.getMonth() + 1; // Los meses empiezan desde 0, por eso se suma 1
    const ano = fechaActual.getFullYear();
    
    // Función para agregar un cero delante del número si es menor que 10
    const formatearNumero = (numero) => {
      return numero < 10 ? `0${numero}` : numero;
    };
    
    // Formatear día y mes
    const diaFormateado = formatearNumero(dia);
    const mesFormateado = formatearNumero(mes);
    
    let date = ano + "-" + mesFormateado + '-' + diaFormateado;

    const { store } = useContext(Context);

    let userLog = localStorage.getItem('Name');

    let res = []

    store.users.forEach((i) => {
        res.push(i.nombre); // Almacena el nombre si cumple con la condición
    });


    const [recipient, setRecipient] = useState("Roy");
    const [estado, setEstado] = useState("Open");
    const [comentarios, setComentarios] = useState("");
    const [fecha, setFecha] = useState("");

    const actualizar = () => {
        window.location.reload(true);
    }

    const crear = e => {
        e.preventDefault();
        const body = {
            sender: userLog,
            estado: estado,
            recipient: recipient,
            content: comentarios,
            estado: estado,
            fecha: date
        };
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/tiquetes/", {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                alert("Tiquet is Created");
                navigate('/tickets');
                actualizar();
            })
            .catch(err => console.log(err));
    };
    let state = ["Open", "Close"]
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="card p-2 ">
                    <h1>Create Ticket</h1>
                    <form onSubmit={crear} >
                        <Row className="g-0 ">
                            <div className="col-lg-2 p-1">
                                <div className="col-12 fw-bold">User</div>
                                <select className="form-select" name="User" aria-label="User" onChange={e => setRecipient(e.target.value)} >
                                    {
                                        res.map((index) => {
                                            return (
                                                <option key={index} name="User" value={index} >{index}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-lg-4 p-1">
                                <div className="col-12 fw-bold">Content </div>
                                <textarea className="form-control selectInner shadow" type="text" rows="5" placeholder="Content" aria-label="Content" onChange={e => setComentarios(e.target.value)} />
                            </div>

                            <div className="col-lg-2 p-1">
                                <div className="col-12 fw-bold">Date </div>
                                <input disabled className="form-control selectInner shadow" type="text" placeholder={date} aria-label="Date" onChange={e => setFecha(e.target.value)} />
                            </div>

                            <div className="col-lg-2 p-1">
                                <div className="col-12 fw-bold">State</div>
                                <select className="form-select" name="State" aria-label="State" onChange={e => setEstado(e.target.value)} >
                                    {
                                        state.map((index) => {
                                            return (
                                                <option key={index} name="State" value={index} >{index}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </Row>
                        <Row>
                            <div className="col-lg-4 py-3">
                                <button type="submit" className="btn btn-success">Save</button>
                            </div>
                        </Row>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
};