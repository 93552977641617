import React, { useContext } from "react";

import ListPercentageClient from "./listPercentageClient";
import { Context } from "../../store/appContext";
import { Row } from "reactstrap";

export default function RecordPercentageClient() {
    const { store } = useContext(Context);
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="g-0 overflow-scroll">
                    <Row className="row g-0 text-center bg-base text-white py-3 min1500">
                        <div className="col lines">Name</div>
                        <div className="col lines">State</div>
                        <div className="col lines">Phone</div>
                        <div className="col lines">EXT</div>
                        <div className="col lines">Email</div>
                        <div className="col lines">Agency</div>
                        <div className="col lines">PPH</div>
                        <div className="col lines">Players PPH</div>
                        <div className="col lines">Price PPH</div>
                        <div className="col lines">Price LB</div>
                        <div className="col lines">Price PB</div>
                        <div className="col lines">LD %</div>
                        <div className="col lines">Comment</div>
                    </Row>


                    {
                        store.clientesPercentage.map((item, index) => {
                            return (
                                <div key={index} className="col-12">
                                    <ListPercentageClient
                                        id={index}
                                        agenteID={item.agenteID}
                                        nombre={item.nombre}
                                        estado={item.estado}
                                        telefono={item.telefono}
                                        email={item.email}
                                        agencia={item.agencia}
                                        PPH_asignado={item.PPH_asignado}
                                        extension_asignada={item.extension_asignada}
                                        jugadores={item.jugadores}
                                        precioPPH={item.precioPPH}
                                        porcentajeLiveDealer={item.porcentajeLiveDealer}
                                        precioLiveBetting={item.precioLiveBetting}
                                        precioLivePropBuilder={item.precioLivePropBuilder}
                                        comentario={item.comentario}
                                    />
                                </div>
                            );
                        })}
                </Row>
            </div>
        </React.Fragment >
    )
}
