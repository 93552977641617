import { useParams } from 'react-router-dom';
import React, { useState, useContext } from "react";
import { Context } from "../../store/appContext";
import { useNavigate } from 'react-router-dom';
import { Row } from "reactstrap";

export default function EditDeposito() {
    document.title = "Deposit | Edit Deposit Info";
    const navigate = useNavigate();
    const params = useParams();
    const { store } = useContext(Context);

    let actualizar = () => {
        window.location.reload(true);
    }

    const marcasSB = []
    store.marcas.forEach((i) => {
        if (i.comentario === "SB") {
            marcasSB.push(i.marca); // Almacena la marca si cumple con la condición
        }
    });

    const agentesPPH = [];
    store.agentes.forEach((i) => {
      agentesPPH.push(i.nombre); // Almacena el nombre si cumple con la condición
    }); 

    const [fecha, setFecha] = useState(store.depositos[params.theid] && store.depositos[params.theid].fecha);
    const [hora, setHora] = useState(store.depositos[params.theid] && store.depositos[params.theid].hora);
    const [jugador, setJugador] = useState(store.depositos[params.theid] && store.depositos[params.theid].jugador);
    const [metodo, setMetodo] = useState(store.depositos[params.theid] && store.depositos[params.theid].metodo);
    
    const [cantidad, setCantidad] = useState(store.depositos[params.theid] && store.depositos[params.theid].cantidad);

    const [comentario, setComentario] = useState(store.depositos[params.theid] && store.depositos[params.theid].comentario);

    const crear = e => {
        e.preventDefault();
        const body = {
            fecha: fecha,
            hora: hora,
            jugador: jugador,
            cantidad: cantidad,
            metodo: metodo,
            comentario: comentario
        };
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/depositos_jugadores/" + store.depositos[params.theid].depositoID, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                alert("Deposit was updated");
                navigate('/deposits');
                actualizar();
            })
            .catch(err => console.log(err));
    };
    const metodoPadgo = ["Crypto", "Deposit"];
    return (
        <React.Fragment>
            <div className="page-content">
            </div>
            <form onSubmit={crear} id="miFormNoticia">
                    <div className="card p-1">
                        <Row className="g-0">
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Date</div>
                                <input className="form-control selectInner shadow" type="date" placeholder="Date" aria-label="Date" defaultValue={store.depositos[params.theid] && store.depositos[params.theid].fecha} onChange={e => setFecha(e.target.value)} />
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Hour</div>
                                <input className="form-control selectInner shadow" type="time" placeholder="Hour" aria-label="Hour" defaultValue={store.depositos[params.theid] && store.depositos[params.theid].hora} onChange={e => setHora(e.target.value)} />
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Player</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Player" aria-label="Player" defaultValue={store.depositos[params.theid] && store.depositos[params.theid].jugador} onChange={e => setJugador(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                            <div className="col-12 fw-bold">Brand</div>
                                <select className="form-select" name="Brand" aria-label="Brand" onChange={e => setComentario(e.target.value)} >
                                    {
                                        marcasSB.map((index) => {
                                            return (
                                                <option key={index} name="ano" value={index} >{index}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Payment method</div>
                                <select className="form-select" name="Payment method" aria-label="Payment method" defaultValue={store.depositos[params.theid] && store.depositos[params.theid].metodo} onChange={e => setMetodo(e.target.value)} >
                                    {
                                        metodoPadgo.map((index) => {
                                            return (
                                                <option key={index} name="ano" value={index} >{index}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Quantity</div>
                                <input className="form-control selectInner shadow" type="number" placeholder="Quantity" aria-label="Quantity" defaultValue={store.depositos[params.theid] && store.depositos[params.theid].cantidad}  onChange={e => setCantidad(e.target.value)} />
                            </div>

                        </Row>
                        <div className="col-12 py-3">
                            <button type="submit" className="btn btn-danger">Edit</button>
                        </div>
                    </div>
                </form>
        </React.Fragment>
    );
};