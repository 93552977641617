import { useParams } from 'react-router-dom';
import React, { useState, useContext } from "react";
import { Context } from "../../store/appContext";
import { useNavigate } from 'react-router-dom';
import { Row } from "reactstrap";


export default function EditActivo() {
    document.title = "Report | Edit Report Info";
    const navigate = useNavigate();
    const params = useParams();
    const { store } = useContext(Context);

    let userLog =   localStorage.getItem('Name');

    const [item, setItem] = useState(store.activos[params.theid] && store.activos[params.theid].item);
    const [modelo, setModelo] = useState(store.activos[params.theid] && store.activos[params.theid].modelo);
    const [comentario, setComentario] = useState(store.activos[params.theid] && store.activos[params.theid].comentario);

    const crear = e => {
        e.preventDefault();
        const body = {
            modelo: modelo,
            item: item,
            comentario: comentario
        };
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/activo/" + store.activos[params.theid].activoID, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                
                alert("The Asset was update");
                navigate('/assets'); // Cambia '/ruta_destino' por la ruta a la que deseas redirigir
                actualizar();
            })
            .catch(err => console.log(err));
    };

    
    let actualizar = () => {
        setTimeout(() => {
            window.location.reload(true);
            console.log("Delayed for 1 second.");
          }, 1000);
    }

    const delet = e => {
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/activo/" + store.activos[params.theid].activoID, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" }
        })
            .then(() => {
                alert("The Asset was deleted");
                navigate('/assets'); // Cambia '/ruta_destino' por la ruta a la que deseas redirigir
                actualizar();
            })
            .catch(err => console.log(err));
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <form onSubmit={crear} id="miFormNoticia">
                    <div className="card p-1">
                        <Row className="g-0">
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Item</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Item" aria-label="Item" defaultValue={store.activos[params.theid] && store.activos[params.theid].item} onChange={e => setItem(e.target.value)} />
                            </div>
                            <div className="col-3 p-1">
                                <div className="col-12 fw-bold">Model</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Model" aria-label="Model" defaultValue={store.activos[params.theid] && store.activos[params.theid].modelo} onChange={e => setModelo(e.target.value)} />
                            </div>
                            <div className="col-6 p-1">
                                <div className="col-12 fw-bold">Comment</div>
                                <textarea maxLength="1500" rows={1} className="form-control selectInner shadow" type="text" placeholder="Comment" aria-label="Comment" defaultValue={store.activos[params.theid] && store.activos[params.theid].comentario} onChange={e => setComentario(e.target.value)} />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-4 py-3">
                                <button type="submit" className="btn btn-success">Edit</button>
                            </div>
                            <div className="col-4 text-start p-3">
                                <button className="btn btn-danger" onClick={delet} data-bs-dismiss="modal">Delete</button>
                            </div>
                        </Row>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};