import React, { useContext, useState } from "react";
import ListMarketingReporte from "./listMarketingReporte";
import { Context } from "../../store/appContext";
import { Row } from "reactstrap";

export default function RecordMarketingReporte() {
    const { store } = useContext(Context);

    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth() + 1;
    const anoActual = fechaActual.getFullYear();

    const formatearMes = (mes) => {
        return mes < 10 ? `0${mes}` : mes;
      };


    const [mesReportMarke, setMesReportMarke] = useState(formatearMes(mesActual));
    const [anoReporMark, setAnoReporMark] = useState(anoActual);

    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="py-4">
                    <div className="col-lg-2">
                        <p>Year</p>
                        <input min={2023} max={2050} className="form-control selectInner shadow" type="number" placeholder={anoReporMark} onChange={e => setAnoReporMark(e.target.value)}></input>
                    </div>
                    <div className="col-lg-2">
                        <p>Month</p>
                        <input  min={1 < 10 ? `0${1}` : 1} max={12} className="form-control selectInner shadow" type="text" placeholder={mesReportMarke} onChange={e => setMesReportMarke(e.target.value)}></input>
                    </div>
                </Row>
                <Row className="g-0 overflow-scroll">
                    <Row className="row g-0 text-center bg-base text-white py-3 min1500">
                        <div className="col-3 lines">Name</div>
                        <div className="col-1 lines">Date</div>
                        <div className="col-2 lines">Brand</div>
                        <div className="col-5 lines">Report</div>
                        <div className="col-1 lines">Edit</div>
                    </Row>

                    {
                        store.marketingReportes.map((item, index) => {
                            if (item.fecha.slice(0, 4) == anoReporMark && item.fecha.slice(5, 7) == mesReportMarke) {
                                return (
                                    <div key={index} className="col-12">
                                        <ListMarketingReporte
                                            id={index}
                                            reporteID={item.reporteID}
                                            fecha={item.fecha}
                                            usuario={item.usuario}
                                            marca={item.marca}
                                            reporte={item.reporte}
                                        />
                                    </div>
                                );
                            }
                        })}
                </Row>
            </div>
        </React.Fragment >
    )
}
