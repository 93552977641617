import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { filterSidebarData } from "./SidebarData";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import withRouter from "../../components/Common/withRouter";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const Sidebar = (props) => {
  const userPuesto = localStorage.getItem("puesto");
  const sidebarData = filterSidebarData(userPuesto);

  const ref = useRef();

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = props.router.location.pathname;
    const fullPath = pathName;
    const ul = document.getElementById("side-menu-item");
    const items = ul.getElementsByTagName("a");

    removeActivation(items);

    let matchingMenuItem = null;
    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
    }
  }, [
    props.router.location.pathname,
  ]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu-item");
    activeMenu();
  }, []);

  useEffect(() => {
    activeMenu();
  }, [activeMenu]);


  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    toggleSidebar()
  },[])

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Cambia el estado de isSidebarOpen
    const sidebar = document.getElementById("sidebar"); // Cambia el ID según tu estructura
    if (sidebar) {
      if (isSidebarOpen) {
        sidebar.classList.remove("header-with-0");
        sidebar.classList.add("header-with-100");
      } else {
        sidebar.classList.remove("header-with-100");
        sidebar.classList.add("header-with-0");
      }
    }

  };

  return (
    <React.Fragment>
      <div id="sidebar" className="vertical-menu header-with-0 pt-5">
        <SimpleBar className="h-100" ref={ref}>
          <div className="col-12">
            <div className="d-block d-lg-none ">
              <button className="btn fs-1" type="button" onClick={toggleSidebar}>
                X
              </button>
            </div>
          </div>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu-item">
              {sidebarData.map((item, key) => (
                <React.Fragment key={key}>
                  {item.isMainMenu ? (
                    <li className="menu-title">{props.t(item.label)}</li>
                  ) : (
                    <li key={key}>
                      <Link
                        to={item.link ? item.link : "/#"}
                        className={item.subItem ? "has-arrow" : ""}
                      >
                        <i className={item.icon} style={{ marginRight: "5px" }}></i>
                        {props.t(item.label)}
                      </Link>
                      {item.subItem && (
                        <ul className="sub-menu">
                          {item.subItem.map((subMenuItem, subKey) => (
                            <li key={subKey}>
                              <Link
                                to={subMenuItem.link ? subMenuItem.link : "/#"}
                                className={subMenuItem.subItem ? "has-arrow" : ""}
                              >
                                {props.t(subMenuItem.sublabel || subMenuItem.label)}
                              </Link>
                              {subMenuItem.subItem && (
                                <ul className="sub-menu">
                                  {subMenuItem.subItem.map((nestedMenuItem, nestedKey) => (
                                    <li key={nestedKey}>
                                      <Link to={nestedMenuItem.link}>{props.t(nestedMenuItem.label)} <i className="bx bxs-chevron-right"></i> </Link>
                                      {nestedMenuItem.subItem && (
                                        <ul className="sub-menu">
                                          {nestedMenuItem.subItem.map((thirdLevelMenuItem, thirdKey) => (
                                            <li key={thirdKey}>
                                              <Link to={thirdLevelMenuItem.link}>{props.t(thirdLevelMenuItem.label)}</Link>
                                            </li>
                                          ))}
                                        </ul>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </div>
        </SimpleBar>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Sidebar));
