import { useParams } from 'react-router-dom';
import React, { useState, useContext } from "react";
import { Context } from "../../store/appContext";
import { useNavigate } from 'react-router-dom';
import { Row } from "reactstrap";

export default function EditJugadorCasino() {
    document.title = "Player | Edit Player Info";
    const navigate = useNavigate();
    const params = useParams();
    const { store } = useContext(Context);

    const marcasCasino = []
    store.marcas.forEach((i) => {
        if (i.comentario === "Casino") {
            marcasCasino.push(i.marca); // Almacena la marca si cumple con la condición
        }
    });


    const [nombre, setNombre] = useState(store.jugadoresCasino[params.theid] && store.jugadoresCasino[params.theid].nombre);
    const [telefono, setTelefono] = useState(store.jugadoresCasino[params.theid] && store.jugadoresCasino[params.theid].telefono);
    const [email, setEmail] = useState(store.jugadoresCasino[params.theid] && store.jugadoresCasino[params.theid].email);
    const [marca, setMarca] = useState(store.jugadoresCasino[params.theid] && store.jugadoresCasino[params.theid].marca);
    const [juego_preferido, setJuego_preferido] = useState(store.jugadoresCasino[params.theid] && store.jugadoresCasino[params.theid].juego_preferido);
    const [deposito, setDeposito] = useState(store.jugadoresCasino[params.theid] && store.jugadoresCasino[params.theid].deposito);
    const [retiros, setRetiros] = useState(store.jugadoresCasino[params.theid] && store.jugadoresCasino[params.theid].retiros);
    const [comentario, setComentario] = useState(store.jugadoresCasino[params.theid] && store.jugadoresCasino[params.theid].comentario);

    const crear = e => {
        e.preventDefault();
        const body = {
            nombre: nombre,
            telefono: telefono,
            email: email,
            marca: marca,
            juego_preferido: juego_preferido,
            deposito: deposito,
            retiros: retiros,
            comentario: comentario
        };
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/info_jugador_casino/" + store.jugadoresCasino[params.theid].jugadorID, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "https://localhost:3000/",
                "Access-Control-Allow-Methods": "PUT"
            }
        })
            .then(res => res.json())
            .then(data => {
                alert("Player  was updated");
                navigate('/players-casino');
                actualizar();
            })
            .catch(err => console.log(err));
    };

    let actualizar = () => {
        window.location.reload(true);

    }

    const delet = e => {
        fetch("https://crmsbapp-7f82716e70c9.herokuapp.com/info_jugador_casino/" + store.jugadoresCasino[params.theid].jugadorID, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" }
        })
            .then(() => {
                alert("Player was Delet");
                navigate('/players-casino');
                actualizar();
            })
            .catch(err => console.log(err));
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <form onSubmit={crear} id="miFormNoticia">
                    <div className="card p-1">
                        <Row className="g-0">
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Name</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Name" aria-label="Name" defaultValue={store.jugadoresCasino[params.theid] && store.jugadoresCasino[params.theid].nombre} onChange={e => setNombre(e.target.value)} />
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Phone</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Phone" aria-label="Phone" defaultValue={store.jugadoresCasino[params.theid] && store.jugadoresCasino[params.theid].telefono} onChange={e => setTelefono(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Email</div>
                                <input className="form-control selectInner shadow" type="mail" placeholder="Email" aria-label="Email" defaultValue={store.jugadoresCasino[params.theid] && store.jugadoresCasino[params.theid].email} onChange={e => setEmail(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">SB</div>
                                <select className="form-select" name="SB" aria-label="SB" defaultValue={store.jugadoresCasino[params.theid] && store.jugadoresCasino[params.theid].marca} onChange={e => setMarca(e.target.value)} >
                                    {
                                        marcasCasino.map((index) => {
                                            return (
                                                <option key={index} name="ano" value={index} >{index}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Favorite Game</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Favorite Game" aria-label="Favorite Game" defaultValue={store.jugadoresCasino[params.theid] && store.jugadoresCasino[params.theid].juego_preferido} onChange={e => setJuego_preferido(e.target.value)} />
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Deposit</div>
                                <input className="form-control selectInner shadow" type="number" placeholder="Deposit" aria-label="Deposit" defaultValue={store.jugadoresCasino[params.theid] && store.jugadoresCasino[params.theid].deposito} onChange={e => setDeposito(e.target.value)} />
                            </div>
                            <div className="col-1 p-1">
                                <div className="col-12 fw-bold">Withdrawals</div>
                                <input className="form-control selectInner shadow" type="number" placeholder="Withdrawals" aria-label="Withdrawals" defaultValue={store.jugadoresCasino[params.theid] && store.jugadoresCasino[params.theid].retiros} onChange={e => setRetiros(e.target.value)} />
                            </div>
                            <div className="col-2 p-1">
                                <div className="col-12 fw-bold">Comment</div>
                                <input className="form-control selectInner shadow" type="text" placeholder="Comment" aria-label="Comment" defaultValue={store.jugadoresCasino[params.theid] && store.jugadoresCasino[params.theid].comentario} onChange={e => setComentario(e.target.value)} />
                            </div>
                        </Row>
                        <Row>
                            <div className="col-4 py-3">
                                <button type="submit" className="btn btn-success">Edit</button>
                            </div>
                            <div className="col-4 text-start p-3">
                                <button className="btn btn-danger" onClick={delet} data-bs-dismiss="modal">Delete</button>
                            </div>
                        </Row>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};